import { EventEmitter, ComponentRef } from '@angular/core';
import { Constants } from '../../types/constants';
import { CsopAlertMessageType } from '../../types/enum/csop-alert-message-type.enum';
import { CsopAlertService } from '../../services/alertservice/csop-alert.service';
var CsopEmailAddressComponent = (function () {
    function CsopEmailAddressComponent(alertService) {
        this.alertService = alertService;
        this.emailLength = 0;
        this.emailTabIndex = 0;
        this.emailValueChange = new EventEmitter();
        this.inlineAlertEmailId = 'email-inline-alert';
        this.inputEmail = '';
        this.inputEmailID = '';
        this.isValidEmail = true;
    }
    Object.defineProperty(CsopEmailAddressComponent.prototype, "emailValue", {
        set: function (email) {
            this.inputEmail = email;
            this.emailValueChange.emit(this.inputEmail);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CsopEmailAddressComponent.prototype, "maxlength", {
        set: function (length) {
            this.emailLength = length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CsopEmailAddressComponent.prototype, "regEx", {
        set: function (value) {
            this.emailRegEx = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CsopEmailAddressComponent.prototype, "tabIndex", {
        set: function (index) {
            this.emailTabIndex = index;
        },
        enumerable: true,
        configurable: true
    });
    CsopEmailAddressComponent.prototype.isValidEmailId = function (emailId) {
        var pattern = this.emailRegEx;
        return !this.testRegEx(emailId, pattern);
    };
    CsopEmailAddressComponent.prototype.onChanged = function () {
        this.inputEmailID = this.inputEmailID.trim();
        this.emailValueChange.emit(this.inputEmailID);
    };
    CsopEmailAddressComponent.prototype.testRegEx = function (data, pattern) {
        var result = data.match(pattern);
        return result === null;
    };
    CsopEmailAddressComponent.prototype.validateEmail = function () {
        var word = this.inputEmailID;
        this.isValidEmail = true;
        if (this.isValidEmailId(word) === false) {
            this.showInlineMessage(CsopAlertMessageType.Danger, Constants.invalidEmailId, Constants.empty, this.inlineAlertEmailId);
            this.isValidEmail = false;
        }
        else {
            this.alertService.close(this.inlineAlertEmailId);
        }
    };
    CsopEmailAddressComponent.prototype.validation = function (value, regExValue) {
        this.inputEmailID = value.trim();
        this.emailRegEx = regExValue;
        this.validateEmail();
        return this.isValidEmail;
    };
    CsopEmailAddressComponent.prototype.showInlineMessage = function (msgType, message, subMessage, alertId) {
        var alert = {
            type: msgType,
            message: message,
            alertComponentId: alertId,
            autoCloseTimer: undefined,
            subMessage: subMessage
        };
        this.alertService.showInline(alert);
    };
    return CsopEmailAddressComponent;
}());
export { CsopEmailAddressComponent };
