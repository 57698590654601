import * as i0 from "./csop-inline-alert.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./csop-inline-alert.component";
var styles_CsopInlineAlertComponent = [i0.styles];
var RenderType_CsopInlineAlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CsopInlineAlertComponent, data: {} });
export { RenderType_CsopInlineAlertComponent as RenderType_CsopInlineAlertComponent };
function View_CsopInlineAlertComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [["class", "failureSmall"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alert.subMessage; _ck(_v, 3, 0, currVal_0); }); }
export function View_CsopInlineAlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "label", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { "success": 0, "warning": 1, "failure": 2 }), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "close-btn handCursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0\u00A0X"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CsopInlineAlertComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, ((_co.alert.type === "success") || (_co.alert.type === "info")), (_co.alert.type === "warning"), (_co.alert.type === "danger")); _ck(_v, 3, 0, currVal_0); var currVal_2 = (_co.alert.subMessage.trim() !== ""); _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.alert.message; _ck(_v, 5, 0, currVal_1); }); }
export function View_CsopInlineAlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_CsopInlineAlertComponent_0, RenderType_CsopInlineAlertComponent)), i1.ɵdid(1, 180224, null, 0, i3.CsopInlineAlertComponent, [], null, null)], null, null); }
var CsopInlineAlertComponentNgFactory = i1.ɵccf("ng-component", i3.CsopInlineAlertComponent, View_CsopInlineAlertComponent_Host_0, { alertmessage: "alertmessage" }, { closed: "closed" }, []);
export { CsopInlineAlertComponentNgFactory as CsopInlineAlertComponentNgFactory };
