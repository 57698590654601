import * as CryptoJS from 'crypto-js';
import * as i0 from "@angular/core";
var StorageService = (function () {
    function StorageService() {
    }
    StorageService.prototype.clear = function () {
        localStorage.clear();
    };
    StorageService.prototype.getItem = function (key) {
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem(key) || '', this.getKey('ukey') || '').toString(CryptoJS.enc.Utf8);
        return bytes;
    };
    StorageService.prototype.getKey = function (key) {
        return localStorage.getItem(key);
    };
    StorageService.prototype.observe = function (key) {
        return this.observe(key);
    };
    StorageService.prototype.removeItem = function (key) {
        localStorage.removeItem(key);
    };
    StorageService.prototype.setItem = function (key, value) {
        value = CryptoJS.AES.encrypt(value, this.getKey('ukey') || '');
        localStorage.setItem(key, value);
    };
    StorageService.prototype.storeKey = function (ukey) {
        localStorage.setItem('ukey', ukey);
    };
    StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(); }, token: StorageService, providedIn: "root" });
    return StorageService;
}());
export { StorageService };
