import { StorageService } from '../../../../../../src/app/core/services';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../src/app/core/services/storage.service";
var UserProfileService = (function () {
    function UserProfileService(storageService) {
        this.storageService = storageService;
        this.keyName = '-userProfile';
    }
    UserProfileService.prototype.checkPermissions = function (appName, profileName, permissionName) {
        var loginUserProfile = this.getUserProfile(appName, profileName);
        if (loginUserProfile !== undefined) {
            return loginUserProfile.permissions.find(function (item) { return item.value === permissionName; }) !== undefined;
        }
        return false;
    };
    UserProfileService.prototype.checkRole = function (appName, profileName, rolename) {
        var loginUserProfile = this.getUserProfile(appName, profileName);
        if (loginUserProfile !== undefined) {
            return loginUserProfile.roles.find(function (item) { return item.roleName === rolename; }) !== undefined;
        }
        return false;
    };
    UserProfileService.prototype.getUserProfile = function (appName, profileFor) {
        var value = this.storageService.getItem(appName + '-' + profileFor + this.keyName);
        if (value !== undefined && value !== '' && value !== null) {
            var loginUserProfile = JSON.parse(value);
            return loginUserProfile;
        }
        return undefined;
    };
    UserProfileService.prototype.saveUserProfile = function (appName, profileFor, userProfile) {
        this.storageService.setItem(appName + '-' + profileFor + this.keyName, JSON.stringify(userProfile));
    };
    UserProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserProfileService_Factory() { return new UserProfileService(i0.ɵɵinject(i1.StorageService)); }, token: UserProfileService, providedIn: "root" });
    return UserProfileService;
}());
export { UserProfileService };
