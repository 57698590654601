import * as i0 from "@angular/core";
var StateService = (function () {
    function StateService() {
        this.clients = [];
    }
    StateService.prototype.registerClient = function (client) {
        var clientId = client.getAttribute('clientid');
        var isClientRegistered = this.clients.filter(function (existingElement) { return existingElement.getAttribute('clientid') === clientId; });
        if (isClientRegistered.length === 0) {
            this.clients.push(client);
        }
    };
    StateService.prototype.setState = function (state, clientId) {
        if (clientId !== '') {
            var client = document.querySelectorAll('[clientid="' + clientId + '"]')[0];
            client.setAttribute('state', state);
        }
        else {
            for (var _i = 0, _a = this.clients; _i < _a.length; _i++) {
                var client = _a[_i];
                client.setAttribute('state', state);
            }
        }
    };
    StateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StateService_Factory() { return new StateService(); }, token: StateService, providedIn: "root" });
    return StateService;
}());
export { StateService };
