import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { CsopProgressBarType } from '../../types/enum/csop-progressbar-type.enum';
import { CsopProgressBarSize } from '../../types/enum/csop-progressbar-size.enum';
import { StorageService } from '../../../../../../src/app/core/services/storage.service';
import { CsopProgressBarService, DataServiceError, HttpActionType, HttpStatusCode, } from '../../../../../../projects/csop-lib/src/public-api';
import { Constants } from '../../types/constants';
import * as i0 from "@angular/core";
import * as i1 from "../progressbarservice/csop-progressbar.service";
import * as i2 from "../../../../../../src/app/core/services/storage.service";
import * as i3 from "@angular/common/http";
var GenericDataService = (function () {
    function GenericDataService(progressBarService, storageService, http) {
        this.progressBarService = progressBarService;
        this.storageService = storageService;
        this.http = http;
        this.apiRoot = 'api';
        this.queryUrl = '';
    }
    GenericDataService.prototype.execute = function (dataRequestOptions) {
        var _this = this;
        this.showHideProgressBar(true, dataRequestOptions.busyIndicatorPlaceHolderId);
        this.queryUrl = this.apiRoot +
            '/' + dataRequestOptions.application +
            '/' + dataRequestOptions.endPointProviderName;
        if (dataRequestOptions.resourceIdentifier !== undefined && dataRequestOptions.resourceIdentifier !== '') {
            this.queryUrl += '/' + dataRequestOptions.resourceIdentifier;
        }
        if (dataRequestOptions.subResourceIdentifier !== undefined) {
            this.queryUrl += '/' + dataRequestOptions.subResourceIdentifier;
        }
        if (dataRequestOptions.actionVerb !== undefined) {
            this.queryUrl += '/' + dataRequestOptions.actionVerb;
        }
        if (dataRequestOptions.url !== undefined) {
            this.queryUrl += dataRequestOptions.url;
        }
        this.queryUrl.toLowerCase();
        if (dataRequestOptions.data instanceof Error === true) {
            return this.handleError(dataRequestOptions)(dataRequestOptions.data);
        }
        var result$;
        switch (dataRequestOptions.method) {
            case HttpActionType.DELETE: {
                result$ = this.http.delete(this.queryUrl, dataRequestOptions.options);
                break;
            }
            case HttpActionType.GET: {
                result$ = this.http.get(this.queryUrl, dataRequestOptions.options);
                break;
            }
            case HttpActionType.POST: {
                result$ = this.http.post(this.queryUrl, dataRequestOptions.data, dataRequestOptions.options);
                break;
            }
            case HttpActionType.PUT: {
                result$ = this.http.put(this.queryUrl, dataRequestOptions.data, dataRequestOptions.options);
                break;
            }
            default: {
                var error = new Error('Unimplemented HTTP method, ' + dataRequestOptions.method);
                result$ = throwError(error);
            }
        }
        return result$.pipe(catchError(this.handleError(dataRequestOptions)), map(function (res) {
            if (res === null) {
                res = undefined;
            }
            _this.showHideProgressBar(false, dataRequestOptions.busyIndicatorPlaceHolderId);
            return res;
        }));
    };
    GenericDataService.prototype.getHttpOptions = function (accessToken) {
        var bearer = 'Bearer ' + accessToken;
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: bearer
            })
        };
        return httpOptions;
    };
    GenericDataService.prototype.showHideProgressBar = function (show, busyIndicatorPlaceholderId) {
        var progressBar = {
            isModal: false,
            message: '',
            progressbarComponentId: busyIndicatorPlaceholderId,
            progType: CsopProgressBarType.Indeterminate,
            showPercentLabel: false,
            spinnerSize: CsopProgressBarSize.Normal,
            visible: show,
            wholePage: true
        };
        this.progressBarService.showHide(progressBar);
    };
    GenericDataService.prototype.handleError = function (reqData) {
        var _this = this;
        return function (err) {
            if (err !== undefined &&
                err.status !== undefined) {
                switch (err.status) {
                    case HttpStatusCode.BadGateway:
                    case HttpStatusCode.SessionTimeout:
                    case HttpStatusCode.GatewayTimeout:
                    case HttpStatusCode.UnknownError:
                        _this.showHideProgressBar(false, reqData.busyIndicatorPlaceHolderId);
                        var event_1 = new Event('session timeout', { bubbles: true, cancelable: false });
                        var app = _this.storageService.getItem('app');
                        var elem = document.getElementsByTagName(app)[0];
                        elem.dispatchEvent(event_1);
                        return of({});
                    case HttpStatusCode.NotFound:
                        _this.showHideProgressBar(false, reqData.busyIndicatorPlaceHolderId);
                        if (reqData.method === HttpActionType.DELETE) {
                            return of({});
                        }
                        break;
                    case HttpStatusCode.Unauthorized:
                        err.error = Constants.unauthorized;
                        window.location.replace('/');
                        break;
                    default:
                        _this.showHideProgressBar(false, reqData.busyIndicatorPlaceHolderId);
                }
            }
            return throwError(new DataServiceError(err));
        };
    };
    GenericDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GenericDataService_Factory() { return new GenericDataService(i0.ɵɵinject(i1.CsopProgressBarService), i0.ɵɵinject(i2.StorageService), i0.ɵɵinject(i3.HttpClient)); }, token: GenericDataService, providedIn: "root" });
    return GenericDataService;
}());
export { GenericDataService };
