/* P r o p r i e t a r y N o t i c e */
/* Unpublished © 2020 Allscripts Healthcare, LLC and/or its affiliates. All Rights
Reserved.
*
* P r o p r i e t a r y N o t i c e: This software has been provided pursuant to a License Agreement, with Allscripts
Healthcare, LLC and/or its affiliates, containing restrictions on its use. This software contains valuable trade secrets
and proprietary information of Allscripts Healthcare, LLC and/or its affiliates and is protected by trade secret and
copyright law. This software may not be copied or distributed in any form or medium, disclosed to any third parties,
or used in any manner not provided for in said License Agreement except with prior written authorization from
Allscripts Healthcare, LLC and/or its affiliates. Notice to U.S. Government Users: This software is “Commercial
Computer Software.”
Allscripts Common Services Operations Portal is a trademark of Allscripts Healthcare, LLC and/or its affiliates.
*
*
*/
/* P r o p r i e t a r y N o t i c e */

import { Component, Input, Output, EventEmitter, ComponentRef } from '@angular/core';

import { Constants } from '../../types/constants';
import { CsopAlertMessageType } from '../../types/enum/csop-alert-message-type.enum';
import { ICsopInlineAlertMessage } from '../../types/interfaces/csop-inline-alert-message.interface';
import { CsopAlertService } from '../../services/alertservice/csop-alert.service';

@Component({
    selector: 'csop-emailaddress',
    templateUrl: './csop-emailaddress.component.html'
})

export class CsopEmailAddressComponent {
    public set emailValue(email: string) {
        this.inputEmail = email;
        this.emailValueChange.emit(this.inputEmail);
    }

    @Input() public set maxlength(length: number) {
        this.emailLength = length;
    }
    // tslint:disable-next-line:no-any
    @Input() public set regEx(value: any) {
        this.emailRegEx = value;
    }
    @Input() public set tabIndex(index: number) {
        this.emailTabIndex = index;
    }
    public componentRef!: ComponentRef<any>; // tslint:disable-line:no-any
    public emailLength = 0;
    // tslint:disable-next-line:no-uninitialized
    // tslint:disable-next-line:no-any
    public emailRegEx: any;
    public emailTabIndex = 0;
    @Output() public emailValueChange = new EventEmitter<string>();
    public inlineAlertEmailId = 'email-inline-alert';
    public inputEmail = '';
    // tslint:disable-next-line:no-uninitialized
    public inputEmailID = '';
    public isValidEmail = true;

    public constructor(
        private readonly alertService: CsopAlertService
    ) {
    }
    public isValidEmailId(emailId: string): boolean {
        const pattern = this.emailRegEx;
        return !this.testRegEx(emailId, pattern);
    }

    public onChanged() {
        this.inputEmailID = this.inputEmailID.trim();
        this.emailValueChange.emit(this.inputEmailID);
    }

    public testRegEx(
        data: string,
        pattern: RegExp
    ): boolean {
        const result = data.match(pattern);
        return result === null;
    }

    public validateEmail() {
        const word = this.inputEmailID;
        this.isValidEmail = true;
        if (this.isValidEmailId(word) === false) {
            this.showInlineMessage(
                CsopAlertMessageType.Danger,
                Constants.invalidEmailId,
                Constants.empty,
                this.inlineAlertEmailId
            );
            this.isValidEmail = false;
        } else {
            this.alertService.close(this.inlineAlertEmailId);
        }
    }

    // tslint:disable-next-line:no-any
    public validation(value: string, regExValue: any): boolean {
        this.inputEmailID = value.trim();
        this.emailRegEx = regExValue;
        this.validateEmail();
        return this.isValidEmail;
    }

    private showInlineMessage(
        msgType: CsopAlertMessageType,
        message: string,
        subMessage: string,
        alertId: string,
    ) {
        const alert: ICsopInlineAlertMessage = {
            type: msgType,
            message: message,
            alertComponentId: alertId,
            autoCloseTimer: undefined,
            subMessage: subMessage
        };
        this.alertService.showInline(alert);
    }
}
