import { EventEmitter, OnDestroy } from '@angular/core';
import { interval } from 'rxjs';
var CsopInlineAlertComponent = (function () {
    function CsopInlineAlertComponent() {
        this.closed = new EventEmitter();
        this.alert = {
            type: '',
            message: '',
            alertComponentId: '',
            autoCloseTimer: 0,
            subMessage: '',
            isRichText: false
        };
    }
    Object.defineProperty(CsopInlineAlertComponent.prototype, "alertmessage", {
        set: function (alertmessage) {
            var _this = this;
            this.alert = alertmessage;
            if (this.alert.autoCloseTimer !== undefined &&
                this.alert.autoCloseTimer > 0) {
                this.timeoutSubscription = interval(this.alert.autoCloseTimer)
                    .subscribe(function () {
                    _this.close();
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    CsopInlineAlertComponent.prototype.close = function () {
        this.closed.next();
    };
    CsopInlineAlertComponent.prototype.ngOnDestroy = function () {
        if (this.timeoutSubscription !== undefined) {
            this.timeoutSubscription.unsubscribe();
        }
    };
    return CsopInlineAlertComponent;
}());
export { CsopInlineAlertComponent };
