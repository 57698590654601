import { AzureAdService } from 'projects/csop-lib/src/lib/services/azureservices/azure-ad-service';
import { ShieldPassiveFederationService } from 'projects/csop-lib/src/lib/services/shieldpassivefederationservice/shield-passive-federation-service';
import { CoreConstants, Applications } from '../constants';
import { StorageService } from '../services';
import * as i0 from "@angular/core";
import * as i1 from "../../../../projects/csop-lib/src/lib/services/shieldpassivefederationservice/shield-passive-federation-service";
import * as i2 from "../services/storage.service";
import * as i3 from "../../../../projects/csop-lib/src/lib/services/azureservices/azure-ad-service";
var ShieldPassiveFedAuthGuard = (function () {
    function ShieldPassiveFedAuthGuard(shieldPassiveFedSvc, storage, azureAdService) {
        this.shieldPassiveFedSvc = shieldPassiveFedSvc;
        this.storage = storage;
        this.azureAdService = azureAdService;
    }
    ShieldPassiveFedAuthGuard.prototype.canActivate = function () {
        var _this = this;
        if (this.storage.getItem('app') === Applications.Sop) {
            return this.shieldPassiveFedSvc.GetRolesAndPermission().map(function (auth) {
                if (auth) {
                    if (auth.isUserAuthenticated !== 'true') {
                        return false;
                    }
                    _this.storage.setItem(CoreConstants.email, auth.username);
                    _this.shieldPassiveFedSvc.sopBrowserSessionTimeout = auth.browserTimeout;
                    _this.storage.setItem(CoreConstants.sopUserRoles, auth.userRoles);
                    return true;
                }
                console.log('not authenticated');
                _this.azureAdService.logout();
                return false;
            });
        }
        else {
            return true;
        }
    };
    ShieldPassiveFedAuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ShieldPassiveFedAuthGuard_Factory() { return new ShieldPassiveFedAuthGuard(i0.ɵɵinject(i1.ShieldPassiveFederationService), i0.ɵɵinject(i2.StorageService), i0.ɵɵinject(i3.AzureAdService)); }, token: ShieldPassiveFedAuthGuard, providedIn: "root" });
    return ShieldPassiveFedAuthGuard;
}());
export { ShieldPassiveFedAuthGuard };
