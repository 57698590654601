import { throwError } from 'rxjs';
import * as i0 from "@angular/core";
var ShieldConfigService = (function () {
    function ShieldConfigService() {
    }
    ShieldConfigService.getShieldConfig = function () {
        var request = new XMLHttpRequest();
        var shieldConfig;
        try {
            request.open('GET', '../assets/shieldsettings.json', false);
            request.send(null);
            shieldConfig = JSON.parse(request.responseText);
        }
        catch (_a) {
            throwError('shield settings are not available');
        }
        return shieldConfig;
    };
    ShieldConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ShieldConfigService_Factory() { return new ShieldConfigService(); }, token: ShieldConfigService, providedIn: "root" });
    return ShieldConfigService;
}());
export { ShieldConfigService };
