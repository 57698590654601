import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "./csop-emailaddress.component";
import * as i3 from "../../services/alertservice/csop-alert.service";
var styles_CsopEmailAddressComponent = [];
var RenderType_CsopEmailAddressComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CsopEmailAddressComponent, data: {} });
export { RenderType_CsopEmailAddressComponent as RenderType_CsopEmailAddressComponent };
export function View_CsopEmailAddressComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "input", [["class", "form-control"], ["name", "inputEmailID"]], [[8, "tabIndex", 0], [1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "blur"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.inputEmailID = $event) !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (_co.validateEmail() !== false);
        ad = (pd_5 && ad);
    } if (("ngModelChange" === en)) {
        var pd_6 = (_co.onChanged() !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(2, 540672, null, 0, i1.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i0.ɵprd(1024, null, i1.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i1.MaxLengthValidator]), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(5, 671744, null, 0, i1.NgModel, [[8, null], [6, i1.NG_VALIDATORS], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(7, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 0, "div", [["class", "content"]], [[8, "id", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_9 = i0.ɵinlineInterpolate(1, "", _co.emailLength, ""); _ck(_v, 2, 0, currVal_9); var currVal_10 = "inputEmailID"; var currVal_11 = _co.inputEmailID; _ck(_v, 5, 0, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.emailTabIndex, ""); var currVal_1 = (i0.ɵnov(_v, 2).maxlength ? i0.ɵnov(_v, 2).maxlength : null); var currVal_2 = i0.ɵnov(_v, 7).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 7).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 7).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 7).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 7).ngClassValid; var currVal_7 = i0.ɵnov(_v, 7).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 7).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_12 = _co.inlineAlertEmailId; _ck(_v, 8, 0, currVal_12); }); }
export function View_CsopEmailAddressComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "csop-emailaddress", [], null, null, null, View_CsopEmailAddressComponent_0, RenderType_CsopEmailAddressComponent)), i0.ɵdid(1, 49152, null, 0, i2.CsopEmailAddressComponent, [i3.CsopAlertService], null, null)], null, null); }
var CsopEmailAddressComponentNgFactory = i0.ɵccf("csop-emailaddress", i2.CsopEmailAddressComponent, View_CsopEmailAddressComponent_Host_0, { maxlength: "maxlength", regEx: "regEx", tabIndex: "tabIndex" }, { emailValueChange: "emailValueChange" }, []);
export { CsopEmailAddressComponentNgFactory as CsopEmailAddressComponentNgFactory };
