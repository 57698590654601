import * as tslib_1 from "tslib";
import { Type } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CsopModalComponent } from '../../components/modal/csop-modal.component';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
var CsopDialogService = (function () {
    function CsopDialogService(modalService) {
        this.modalService = modalService;
    }
    CsopDialogService.prototype.closeDialog = function () {
        this.modalService.dismissAll();
    };
    CsopDialogService.prototype.openConfirmDialog = function (title, message, btnOkText, btnCancelText) {
        if (btnOkText === void 0) { btnOkText = 'OK'; }
        if (btnCancelText === void 0) { btnCancelText = 'Cancel'; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2, this.openDialogByType(CsopModalComponent, { title: title, message: message, btnOkText: btnOkText, btnCancelText: btnCancelText })];
            });
        });
    };
    CsopDialogService.prototype.openDialogByDetail = function (dialogDetail, baseDialogOptions) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modalRef;
            return tslib_1.__generator(this, function (_a) {
                modalRef = this.modalService.open(CsopModalComponent, tslib_1.__assign({ backdrop: 'static' }, baseDialogOptions));
                Object.assign(modalRef.componentInstance, {
                    dialogDetail: dialogDetail
                });
                return [2, modalRef.result];
            });
        });
    };
    CsopDialogService.prototype.openDialogByType = function (content, config, options) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modalRef;
            return tslib_1.__generator(this, function (_a) {
                modalRef = this.modalService.open(content, tslib_1.__assign({ backdrop: 'static' }, options));
                Object.assign(modalRef.componentInstance, config);
                return [2, modalRef.result];
            });
        });
    };
    CsopDialogService.prototype.openWarningDialog = function (title, message, showOkButton, showCancelButton) {
        if (showOkButton === void 0) { showOkButton = false; }
        if (showCancelButton === void 0) { showCancelButton = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2, this.openDialogByType(CsopModalComponent, { title: title, message: message, showOkButton: showOkButton, showCancelButton: showCancelButton })];
            });
        });
    };
    CsopDialogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CsopDialogService_Factory() { return new CsopDialogService(i0.ɵɵinject(i1.NgbModal)); }, token: CsopDialogService, providedIn: "root" });
    return CsopDialogService;
}());
export { CsopDialogService };
