import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OnDestroy, OnInit, AfterViewInit, } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationStart, Router, ActivatedRoute, } from '@angular/router';
import { fromEvent, Observable, timer, } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { TokenManagerService } from '../../../projects/csop-lib/src/lib/services/tokenmanagerservice/tokenmanager.service';
import { AuthState } from '../core/stores/Auth/auth.state';
import { AuthScopeInformation, UserAccessProfile, } from '../core/model';
import { CoreConstants, Applications, } from '../core/constants';
import { StoreUserProfile } from '../core/stores/Auth/store-userprofile.action';
import { ProfileRoleHelper, SharedFunctions } from '../core/helpers';
import { StateService } from '../state.service';
import { StorageService } from '../core/services/storage.service';
import { CsopUserIdleService } from '../../../projects/csop-lib/src/lib/services/userIdleService/csop-user-idle-service';
import { CsopSignalRService } from '../../../projects/csop-lib/src/lib/services/signalRservice/csop-signalr.service';
import { AlertMessageType } from '../../../projects/csop-lib/src/lib/types/enum/alert-message-type.enum';
import { ConfigService } from '../../../projects/csop-lib/src/lib/services/configservice/configuration.service';
import { LoginService } from '../../../projects/csop-lib/src/lib/services/loginservices/login.service';
import { ToasterService } from '../../../projects/csop-lib/src/lib/services/toastrservice/toastr.service';
import { AzureAdService } from '../../../projects/csop-lib/src/lib/services/azureservices/azure-ad-service';
import { ShieldPassiveFederationService } from '../../../projects/csop-lib/src/lib/services/shieldpassivefederationservice/shield-passive-federation-service';
import { AuthenticationType, CsopAlertMessageType, CsopAlertService, CsopEventType, CsopDialogService, CsopSwapEventType, Constants, CsopProgressBarService, CsopLibSharedFunctions, } from '../../../projects/csop-lib/src/public-api';
import { Logout } from '../core/stores/Auth/auth.logout.action';
import { UserProfileService } from '../../../projects/csop-lib/src/lib/services/userprofileservice/userprofile.service';
var CoreAppContainerComponent = (function () {
    function CoreAppContainerComponent(alertService, azureAdService, configService, dialogService, location, loginService, profileRoleHelper, router, stateSvc, store, storage, toastr, signalrService, progressBarService, http, shieldPassiveFedSvc, csopUserIdleSvc, activeRoute, tokenManagerService, userProfileService) {
        var _this = this;
        this.alertService = alertService;
        this.azureAdService = azureAdService;
        this.configService = configService;
        this.dialogService = dialogService;
        this.location = location;
        this.loginService = loginService;
        this.profileRoleHelper = profileRoleHelper;
        this.router = router;
        this.stateSvc = stateSvc;
        this.store = store;
        this.storage = storage;
        this.toastr = toastr;
        this.signalrService = signalrService;
        this.progressBarService = progressBarService;
        this.http = http;
        this.shieldPassiveFedSvc = shieldPassiveFedSvc;
        this.csopUserIdleSvc = csopUserIdleSvc;
        this.activeRoute = activeRoute;
        this.tokenManagerService = tokenManagerService;
        this.userProfileService = userProfileService;
        this.alertComponentId = 'csop-app-container-alert';
        this.application = [];
        this.deepLinkingUrl = '';
        this.isAppSwitchSelected = false;
        this.progressBarId = 'csop-app-container-progressbar';
        this.swappedApp = '';
        this.targetedApp = Constants.empty;
        this.toggle = false;
        this.userName = '';
        this.currentInteration = 0;
        this.inactivityTimeout = Constants.inactivityTimeout;
        this.reload = true;
        this.sopSessionSchedulerTime = Constants.DefaultSessionSchedulerTime;
        this.targetedUrlIsAzureAd = false;
        this.appRequest = fromEvent(document, CsopEventType[CsopEventType.Request]);
        this.appNotification = fromEvent(document, CsopEventType[CsopEventType.Notify]);
        this.userProfile = new UserAccessProfile('', []);
        this.defaultApp = CoreConstants.empty;
        this.appTitle = CoreConstants.empty;
        this.appName = CoreConstants.empty;
        if (!this.storage.getKey('ukey')) {
            this.storage.storeKey(JSON.stringify(SharedFunctions.makeRandomString(CoreConstants.keyLength)));
        }
        this.application = this.configService.config.csopsettings.applications.sort(function (a, b) { return (a.renderingOrder > b.renderingOrder) ? 1 : -1; });
        this.deepLinkingUrl = this.storage.getItem('deepLinkingUrl') !== '' ? this.storage.getItem('deepLinkingUrl') : '/';
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                _this.targetedUrlIsAzureAd = event.url.includes('azureAd') ? true : false;
            }
        });
    }
    CoreAppContainerComponent.prototype.closeSidenav = function () {
        this.toggle = !this.toggle;
    };
    CoreAppContainerComponent.prototype.logout = function () {
        var _this = this;
        this.removeContent();
        if (this.targetedAppSettings !== undefined) {
            if (this.targetedAppSettings.authenticationMechanism.name === AuthenticationType.AzureAd
                && this.azureAdService.isUserAuthenticated()) {
                if (this.selectedApplication !== undefined) {
                    this.azureAdService.selectedApplication = this.selectedApplication.appName;
                }
                else {
                    this.azureAdService.selectedApplication = this.storage.getItem(Constants.app);
                }
                this.targetedUrlIsAzureAd = true;
                this.store.dispatch(new Logout());
            }
            else if (this.targetedAppSettings.authenticationMechanism.name === AuthenticationType.ShieldPassiveFederation) {
                this.shieldPassiveFedSvc.logout().subscribe(function () {
                    _this.storage.clear();
                    _this.loginService.redirectToAppLauncher();
                }, function (error) {
                    console.error(error);
                });
            }
            else {
                this.storage.clear();
                this.loginService.redirectToAppLauncher();
            }
        }
    };
    CoreAppContainerComponent.prototype.ngAfterViewInit = function () {
        this.progressBarService.showHideProgressBar(true, this.progressBarId);
    };
    CoreAppContainerComponent.prototype.ngOnDestroy = function () {
        var content = document.getElementsByTagName('app-app-container');
        if (content !== null) {
            content[0].remove();
        }
        if (this.appNotificationSubscription !== undefined) {
            this.appRequestSubscription.unsubscribe();
        }
        if (this.appRequestSubscription !== undefined) {
            this.appRequestSubscription.unsubscribe();
        }
    };
    CoreAppContainerComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var intialSelectedApp, authenticationProvider, delay;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isAppSwitchSelected = false;
                this.targetedUrlIsAzureAd = false;
                this.storage.setItem(Constants.sessionTime, Date.now().toString());
                this.defaultApp = this.storage.getItem(Constants.app);
                intialSelectedApp = this.application.find(function (app) { return app.appName === _this.defaultApp; });
                authenticationProvider = '';
                if (intialSelectedApp !== undefined) {
                    this.appTitle = intialSelectedApp.appTitle;
                    this.appName = intialSelectedApp.appName;
                    this.targetedAppSettings = this.getAppSetting(intialSelectedApp.appName);
                    authenticationProvider = intialSelectedApp.authenticationMechanism.name;
                }
                this.detectUserAction();
                this.http.get('SessionStart?appName=' + this.appName, {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json'
                    })
                }).subscribe(function (res) {
                    _this.inactivityTimeout = Number(res);
                    var maxIterationRequire = 1;
                    console.log('Configure Session Timeout = ', _this.inactivityTimeout);
                    if (intialSelectedApp !== undefined && authenticationProvider === AuthenticationType.Shield) {
                        maxIterationRequire = Math.ceil(_this.inactivityTimeout / 18);
                        if (maxIterationRequire === 1) {
                            _this.currentInteration = maxIterationRequire + 1;
                        }
                        else {
                            _this.inactivityTimeout = 18;
                        }
                        console.log('Max Iteration Require = ', maxIterationRequire);
                    }
                    console.log('AuthenticationType', authenticationProvider);
                    _this.csopUserIdleSvc.startWatching((_this.inactivityTimeout - 1) * 60, authenticationProvider).subscribe(function (inactivitytimer) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var _a, appSetting, authProvider;
                        return tslib_1.__generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    console.log('currentInteration', this.currentInteration);
                                    console.log('maxIterationRequire', maxIterationRequire);
                                    this.currentInteration = this.currentInteration + 1;
                                    if (!(this.currentInteration <= maxIterationRequire && authenticationProvider === AuthenticationType.Shield)) return [3, 1];
                                    console.log('Current Iteration Count = ', this.currentInteration);
                                    console.log('Starting of Get Refresh Token');
                                    this.refreshShieldToken(this.appName);
                                    console.log('End Calling to Refresh Token. ', new Date().toString());
                                    this.csopUserIdleSvc.schedulerTime = Constants.DefaultSessionSchedulerTime;
                                    this.csopUserIdleSvc.resetTimer();
                                    return [3, 3];
                                case 1:
                                    console.log('csopUserIdleSvc - schedulerTime:', this.csopUserIdleSvc.schedulerTime);
                                    if (this.csopUserIdleSvc.schedulerTime === 0 && this.sopSessionSchedulerTime > Constants.DefaultSessionSchedulerTime) {
                                        this.csopUserIdleSvc.schedulerTime = Constants.DefaultSessionSchedulerTime;
                                        this.csopUserIdleSvc.stopTimer();
                                        inactivitytimer = Constants.DefaultSessionSchedulerTime;
                                    }
                                    console.log('startWatching - inactivitytimer:', inactivitytimer);
                                    if (inactivitytimer === 59 && this.sopSessionSchedulerTime === Constants.DefaultSessionSchedulerTime) {
                                        this.logout();
                                    }
                                    this.setMsgForSessionTimeoutWarning(inactivitytimer);
                                    if (!(inactivitytimer === 0 && this.sopSessionSchedulerTime === Constants.DefaultSessionSchedulerTime)) return [3, 3];
                                    _a = this;
                                    return [4, this.dialogService.openConfirmDialog(CoreConstants.confirmationMessage, 'You will be logged out in 59 seconds. \n Do you want to stay signed in ?', CoreConstants.dlgBtnYes, CoreConstants.dlgBtnNo)];
                                case 2:
                                    _a.result = (_b.sent());
                                    if (this.result === true) {
                                        this.csopUserIdleSvc.schedulerTime = Constants.DefaultSessionSchedulerTime;
                                        this.currentInteration = 0;
                                        this.csopUserIdleSvc.resetTimer();
                                        appSetting = this.getAppSetting(this.appName);
                                        if (appSetting !== undefined) {
                                            authProvider = appSetting.authenticationMechanism;
                                            if (authProvider.name === AuthenticationType.ShieldPassiveFederation) {
                                                this.refreshSOPSession();
                                            }
                                            else if (authProvider.name === AuthenticationType.Shield) {
                                                this.refreshShieldToken(this.appName);
                                            }
                                            else {
                                                this.continueSession();
                                            }
                                        }
                                    }
                                    else {
                                        this.logout();
                                    }
                                    _b.label = 3;
                                case 3: return [2];
                            }
                        });
                    }); });
                });
                this.storage.setItem('sessionStatus', 'true');
                delay = 100;
                SharedFunctions.sleep(delay);
                if (intialSelectedApp !== undefined) {
                    if (intialSelectedApp.authenticationMechanism.name === AuthenticationType.AzureAd) {
                        if (this.azureAdService.isUserAuthenticated()) {
                            if (this.storage.getItem(CoreConstants.isUserLoggedIn) === Constants.empty) {
                                this.storage.setItem(CoreConstants.isUserLoggedIn, 'true');
                                this.signalrService.startConnection();
                            }
                            this.processAzureAdRequest();
                        }
                    }
                    else if (intialSelectedApp.authenticationMechanism.name === AuthenticationType.ShieldPassiveFederation) {
                        this.userName = this.storage.getItem(CoreConstants.email);
                        if (this.storage.getItem(CoreConstants.isUserLoggedIn) === Constants.empty) {
                            this.storage.setItem(CoreConstants.isUserLoggedIn, 'true');
                            this.signalrService.startConnection();
                        }
                        this.userProfile.appProfiles = this.profileRoleHelper.getApplicationProfiles();
                        this.setSelectedApplication(this.storage.getItem('app'));
                        console.log('Constants DefaultSOPBrowserSessionTimeout:', Constants.DefaultSOPBrowserSessionTimeout);
                        console.log('shieldPassiveFedSvc - sopBrowserSessionTimeout:', this.shieldPassiveFedSvc.sopBrowserSessionTimeout);
                        if (this.shieldPassiveFedSvc.sopBrowserSessionTimeout !== Constants.DefaultSOPBrowserSessionTimeout) {
                            this.startBrowserSessionTimer(this.shieldPassiveFedSvc.sopBrowserSessionTimeout);
                        }
                    }
                    else {
                        if (this.loginService.isUserAuthenticated) {
                            this.userName = this.storage.getItem('fullName');
                            if (this.storage.getItem(CoreConstants.isUserLoggedIn) === Constants.empty) {
                                this.storage.setItem(CoreConstants.isUserLoggedIn, 'true');
                                this.signalrService.startConnection();
                            }
                            this.userProfile.appProfiles = this.profileRoleHelper.getApplicationProfiles();
                            this.setSelectedApplication(this.storage.getItem('app'));
                        }
                        else {
                            this.toastr.warning('User is not authorized to access any application.');
                        }
                    }
                }
                return [2];
            });
        });
    };
    CoreAppContainerComponent.prototype.onAppChange = function (app) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sameApp, result;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sameApp = this.storage.getItem('app');
                        if (!(sameApp !== app.appName)) return [3, 2];
                        this.toggle = false;
                        return [4, this.dialogService.openConfirmDialog(CoreConstants.dlgTitleConfirmation, 'Please save your work before navigating to the ' + app.appTitle, CoreConstants.dlgBtnSaveContinue, CoreConstants.dlgBtnCancel).catch(function (err) {
                                console.error(err);
                            })];
                    case 1:
                        result = _a.sent();
                        if (result === true) {
                            this.targetedAppSettings = this.getAppSetting(app.appName);
                            if (this.targetedAppSettings !== undefined) {
                                this.appTitle = this.targetedAppSettings.appTitle;
                                this.isAppSwitchSelected = true;
                                this.storage.setItem(Constants.app, app.appName);
                                this.storage.setItem(CoreConstants.email, Constants.empty);
                                switch (this.targetedAppSettings.authenticationMechanism.name) {
                                    case AuthenticationType.AzureAd:
                                        if (this.azureAdService.isUserAuthenticated()) {
                                            this.swappedApp = app.appName;
                                            this.setSelectedApplication(app.appName);
                                            this.processAzureAdRequest();
                                        }
                                        else {
                                            this.storage.setItem(CoreConstants.isUserLoggedIn, Constants.empty);
                                            this.router.navigate(['/azureAd'], { queryParams: { app: app.appName } });
                                        }
                                        break;
                                    case AuthenticationType.Shield:
                                        this.isAuthorized(this.targetedAppSettings).then(function (response) {
                                            if (response === true) {
                                                _this.storage.setItem(Constants.app, _this.defaultApp);
                                                _this.swappedApp = app.appName;
                                                if (_this.storage.getItem(Constants.app) === Applications.FoD) {
                                                    _this.sendSwapEvent();
                                                }
                                                else {
                                                    _this.userName = _this.storage.getItem('fullName');
                                                    _this.setSelectedApplication(app.appName);
                                                }
                                            }
                                            else {
                                                _this.storage.setItem(CoreConstants.isUserLoggedIn, Constants.empty);
                                                _this.router.navigate(['/login'], { queryParams: { app: app.appName } });
                                            }
                                        }).catch(function (error) {
                                            console.log(error);
                                            _this.showToasterMessage(AlertMessageType.Danger, 'You are not authorized to access ' + app.appTitle + '.');
                                        });
                                        break;
                                    case AuthenticationType.ShieldPassiveFederation:
                                        if (this.shieldPassiveFedSvc.isUserAuthenticated()) {
                                            this.shieldPassiveFedSvc.GetRolesAndPermission().subscribe(function (authData) {
                                                if (authData) {
                                                    if (authData.isUserAuthenticated === 'true') {
                                                        _this.userName = authData.username;
                                                        _this.shieldPassiveFedSvc.sopBrowserSessionTimeout = authData.browserTimeout;
                                                        _this.setSelectedApplication(app.appName);
                                                        _this.startBrowserSessionTimer(authData.browserTimeout);
                                                    }
                                                    else {
                                                        _this.redirectToSopLoginPage();
                                                    }
                                                }
                                                else {
                                                    _this.redirectToSopLoginPage();
                                                }
                                            });
                                        }
                                        else {
                                            this.redirectToSopLoginPage();
                                        }
                                        break;
                                    default: break;
                                }
                            }
                        }
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    CoreAppContainerComponent.prototype.processAzureAdRequest = function () {
        var _this = this;
        this.azureAdService.getIdToken()
            .then(function (idToken) {
            if (idToken !== undefined) {
                _this.storage.setItem(Constants.app, Applications.CareQuality);
                var userAccessProfile = _this.profileRoleHelper.getUserAccessProfile(idToken);
                _this.userName = userAccessProfile.userName;
                if (userAccessProfile.appProfiles.length === 0) {
                    SharedFunctions.sleep(CoreConstants.sleepTimeMs);
                    userAccessProfile = _this.profileRoleHelper.getUserAccessProfile(idToken);
                }
                if (!_this.loginService.verifyEmail(userAccessProfile.userName)) {
                    _this.logout();
                    return;
                }
                if (userAccessProfile.appProfiles.length === 0) {
                    _this.showMessage(CsopAlertMessageType.Danger, CoreConstants.notAuthorizedMsg);
                }
                else {
                    _this.store.dispatch(new StoreUserProfile(userAccessProfile))
                        .subscribe(function (dataProfile) {
                        if (dataProfile !== undefined &&
                            dataProfile.auth !== undefined) {
                            _this.userProfile = dataProfile.auth;
                            _this.setSelectedApplication(Applications.CareQuality);
                            _this.appNotificationSubscription = _this.appNotification.subscribe(function (data) {
                                var coreEvent = data.detail;
                                _this.toastr.info(coreEvent.payload, 'App ID::' + coreEvent.elementId);
                            });
                            _this.appRequestSubscription = _this.appRequest.subscribe(function (data) {
                                var coreEvent = data.detail;
                                if (_this.user$ !== undefined) {
                                    _this.user$.subscribe(function (user) {
                                        var docEvent = new CustomEvent(CsopEventType[CsopEventType.Response] + '_' + coreEvent.elementId, { detail: user.token });
                                        document.dispatchEvent(docEvent);
                                    });
                                }
                            });
                        }
                        else {
                            _this.toastr.warning('You are not authorized to access any application.');
                        }
                    }, function (error) {
                        console.error(error);
                    });
                }
            }
        }, function (error) {
            _this.toastr.error('Failed to load Id token.');
            console.error(error);
        });
    };
    CoreAppContainerComponent.prototype.startBrowserSessionTimer = function (timeoutMinutes) {
        var _this = this;
        this.sopSessionTimer$ = this.startTimer(timeoutMinutes).subscribe(function (resp) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var resultDialog;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (resp === 0 && this.csopUserIdleSvc.schedulerTime > Constants.DefaultSessionSchedulerTime) {
                            this.sopSessionSchedulerTime = resp;
                            return [2];
                        }
                        this.sopSessionSchedulerTime = resp;
                        if (resp > Constants.MaxTimerLimit && this.csopUserIdleSvc.schedulerTime === Constants.DefaultSessionSchedulerTime) {
                            this.toastr.error('Your session has expired. Please log in again.');
                            if (this.sopSessionTimer$ !== undefined) {
                                this.sopSessionTimer$.unsubscribe();
                            }
                            this.logout();
                        }
                        this.setMsgForSessionTimeoutWarning(resp);
                        if (!(resp === 0 && this.csopUserIdleSvc.schedulerTime === Constants.DefaultSessionSchedulerTime)) return [3, 2];
                        return [4, this.dialogService.openConfirmDialog(CoreConstants.confirmationMessage, 'You will be logged out in 59 seconds. \n Do you want to stay signed in ?', CoreConstants.dlgBtnYes, CoreConstants.dlgBtnNo).catch(function (err) {
                                console.log(err);
                                _this.logout();
                            })];
                    case 1:
                        resultDialog = (_a.sent());
                        _a.label = 2;
                    case 2:
                        if (resultDialog === true) {
                            this.sopSessionTimer$.unsubscribe();
                            this.refreshSOPSession();
                        }
                        else if (resultDialog === false) {
                            this.sopSessionTimer$.unsubscribe();
                            this.logout();
                        }
                        return [2];
                }
            });
        }); });
    };
    CoreAppContainerComponent.prototype.windowCloseEvent = function () {
        var _this = this;
        if (window.localStorage !== undefined) {
            window.localStorage['myUnloadEventFlag'] = new Date().getTime();
        }
        if (!this.targetedUrlIsAzureAd) {
            if (this.isAppSwitchSelected === false) {
                setTimeout(function () {
                    if (_this.reload === true) {
                        _this.logout();
                    }
                }, 5000);
            }
        }
    };
    CoreAppContainerComponent.prototype.windowReload = function () {
        if (!window.location.href.includes('sop-app')) {
            return;
        }
        if (window.localStorage !== undefined) {
            var t0 = Number(window.localStorage['myUnloadEventFlag']);
            if (isNaN(t0)) {
                t0 = 0;
            }
            var t1 = new Date().getTime();
            var duration = t1 - t0;
            if (duration < 10 * 1000) {
                this.reload = false;
            }
        }
    };
    CoreAppContainerComponent.prototype.continueSession = function () {
        this.http.get('SessionContinue', {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }).subscribe();
    };
    CoreAppContainerComponent.prototype.detectUserAction = function () {
        var _this = this;
        this.csopUserIdleSvc.observeUserActivity.subscribe(function (authProvider) {
            if (authProvider === AuthenticationType.Shield) {
                _this.currentInteration = 0;
            }
            else if (authProvider === AuthenticationType.ShieldPassiveFederation) {
                console.log('In ShieldPassiveFederation');
                _this.csopUserIdleSvc.resetTimer();
                if (Math.abs((Date.now() - _this.storage.getItem(Constants.sessionTime)) / (1000 * 60)) >= 1) {
                    _this.storage.setItem(Constants.sessionTime, Date.now().toString());
                    _this.continueSession();
                }
            }
        });
    };
    CoreAppContainerComponent.prototype.getAppSetting = function (appName) {
        var appSetting = this.application.find(function (app) { return app.appName === appName; });
        return appSetting !== undefined ? appSetting : undefined;
    };
    CoreAppContainerComponent.prototype.getShieldAuthenticated = function (appSettings) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var token, anotherShieldApp, scopeValues, tokenKey, _a;
                        return tslib_1.__generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    token = null;
                                    anotherShieldApp = this.application.find(function (app) {
                                        return app.authenticationMechanism.name === AuthenticationType.Shield
                                            && app.appName !== appSettings.appName;
                                    });
                                    if (!(anotherShieldApp !== undefined)) return [3, 4];
                                    scopeValues = CsopLibSharedFunctions.getAuthenticationSettingScopeValue(anotherShieldApp.authenticationMechanism.setting);
                                    if (scopeValues !== undefined) {
                                        tokenKey = anotherShieldApp.appName + '-' + scopeValues.tokenType;
                                        token = this.storage.getItem(tokenKey);
                                    }
                                    if (!(token !== null && token !== undefined && token !== '')) return [3, 1];
                                    resolve(true);
                                    return [3, 3];
                                case 1:
                                    _a = resolve;
                                    return [4, this.loginService.getRefreshTokenPromise(appSettings.appName, anotherShieldApp.appName)];
                                case 2:
                                    _a.apply(void 0, [_b.sent()]);
                                    _b.label = 3;
                                case 3: return [3, 5];
                                case 4:
                                    resolve(false);
                                    _b.label = 5;
                                case 5: return [2];
                            }
                        });
                    }); })];
            });
        });
    };
    CoreAppContainerComponent.prototype.handleMessage = function (msg) {
        this.toastr.info('shell received message: ', msg.detail);
    };
    CoreAppContainerComponent.prototype.handleSessionTimeout = function () {
        var _this = this;
        if (this.activeToast === undefined ||
            (this.activeToast !== undefined &&
                this.activeToast.message !== 'Session Timed out!')) {
            this.activeToast = this.toastr.error('Session Timed out!');
            var delay = 2000;
            setTimeout(function () {
                _this.logout();
            }, delay);
        }
    };
    CoreAppContainerComponent.prototype.handleSwapping = function (swapEvent) {
        switch (swapEvent) {
            case CsopSwapEventType.Continue:
                this.setSelectedApplication(this.swappedApp);
                break;
            case CsopSwapEventType.Cancel:
                this.defaultApp = this.storage.getItem(Constants.app);
        }
    };
    CoreAppContainerComponent.prototype.isAuthorized = function (appSettings) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var token, scopeValues;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    token = null;
                                    if (!(appSettings !== undefined)) return [3, 4];
                                    scopeValues = CsopLibSharedFunctions.getAuthenticationSettingScopeValue(appSettings.authenticationMechanism.setting);
                                    if (scopeValues !== undefined) {
                                        token = this.storage.getItem(appSettings.appName + '-' + scopeValues.tokenType);
                                    }
                                    if (!(token === null || token === undefined || token === '')) return [3, 2];
                                    return [4, this.getShieldAuthenticated(appSettings)
                                            .catch(function (err) {
                                            console.error(err);
                                            resolve(false);
                                        })
                                            .then(function (res) {
                                            res === true ? resolve(true) : resolve(false);
                                        })];
                                case 1:
                                    _a.sent();
                                    return [3, 3];
                                case 2:
                                    resolve(true);
                                    _a.label = 3;
                                case 3: return [3, 5];
                                case 4:
                                    resolve(false);
                                    _a.label = 5;
                                case 5: return [2];
                            }
                        });
                    }); })];
            });
        });
    };
    CoreAppContainerComponent.prototype.loadApplication = function (app) {
        var _this = this;
        var content = document.getElementById('content');
        if (content === null || content === undefined) {
            SharedFunctions.sleep(CoreConstants.sleepTimeMs);
            content = document.getElementById('content');
        }
        if (content !== null) {
            var script = document.createElement('script');
            script.src = app.appModulePath;
            content.appendChild(script);
            var element = document.createElement(app.appName);
            content.appendChild(element);
            element.addEventListener('message', function (msg) { return _this.handleMessage(msg); });
            element.addEventListener('session timeout', function () { return _this.handleSessionTimeout(); });
            content.addEventListener(CsopSwapEventType[CsopSwapEventType.Continue], function () { return _this.handleSwapping(CsopSwapEventType.Continue); });
            content.addEventListener(CsopSwapEventType[CsopSwapEventType.Cancel], function () { return _this.handleSwapping(CsopSwapEventType.Cancel); });
            element.setAttribute('clientid', app.appName);
            element.setAttribute('state', JSON.stringify(this.userProfile.appProfiles.find(function (t) { return t.appName === app.appName; })));
            script.onerror = function () { return console.error("error loading " + app.appName); };
            this.stateSvc.registerClient(element);
        }
    };
    CoreAppContainerComponent.prototype.redirectToSopLoginPage = function () {
        window.location.href = '/ShieldPassiveFederation/IssueSAMLRequest';
    };
    CoreAppContainerComponent.prototype.refreshShieldToken = function (appName) {
        var appSettings = this.configService.config.csopsettings.applications.filter(function (app) {
            return app.appName.toLowerCase() === appName.toLowerCase();
        });
        var tokenScopeConfig = appSettings[0].authenticationMechanism.setting.filter(function (setting) {
            return setting.name.toLowerCase() === CoreConstants.defaultAuthenticationSetting.toLowerCase();
        });
        var authProvider = this.configService.config.csopsettings.authProviders.filter(function (provider) { return provider.name === AuthenticationType.Shield; })[0];
        if (tokenScopeConfig.length > 0) {
            var scope = new AuthScopeInformation(tokenScopeConfig[0].relyingParty, tokenScopeConfig[0].scope, tokenScopeConfig[0].scopeValue, tokenScopeConfig[0].issueCompressToken);
            var userProfile = this.tokenManagerService.fetchTokenForShield(appName, authProvider, scope);
            if (userProfile !== undefined) {
                this.userProfileService.saveUserProfile(appName, CoreConstants.defaultAuthenticationSetting, userProfile);
                console.log('Profile Saved');
            }
            else {
                console.log('Token not Refreshed');
                this.logout();
            }
        }
    };
    CoreAppContainerComponent.prototype.refreshSOPSession = function () {
        var _this = this;
        this.removeContent();
        this.shieldPassiveFedSvc.logout().subscribe(function () {
            _this.storage.clear();
            if (!_this.storage.getKey('ukey')) {
                _this.storage.storeKey(JSON.stringify(SharedFunctions.makeRandomString(CoreConstants.keyLength)));
            }
            _this.storage.setItem(Constants.app, 'sop-app');
            _this.isAppSwitchSelected = true;
            _this.redirectToSopLoginPage();
        }, function (err) {
            console.log(err);
            _this.isAppSwitchSelected = true;
            _this.loginService.redirectToAppLauncher();
        });
    };
    CoreAppContainerComponent.prototype.removeContent = function () {
        var content = document.getElementById('content');
        if (content !== null) {
            while (content.firstChild !== null) {
                content.removeChild(content.firstChild);
            }
        }
    };
    CoreAppContainerComponent.prototype.sendSwapEvent = function () {
        var swapEvent = new Event(CsopSwapEventType[CsopSwapEventType.Notify], { bubbles: false, cancelable: false });
        var app = this.storage.getItem('app');
        var appElement = document.getElementsByTagName(app)[0];
        appElement.dispatchEvent(swapEvent);
    };
    CoreAppContainerComponent.prototype.setMsgForSessionTimeoutWarning = function (seconds) {
        var remainingSeconds = (Constants.Seconds - 1) - seconds;
        if (remainingSeconds <= -1) {
            this.logout();
        }
        var msg = 'You will be logged out in <b>' + remainingSeconds + '</b> seconds. \n Do you want to stay signed in ?';
        var modelbodydiv = document.getElementsByClassName('modal-body')[0];
        if (modelbodydiv !== undefined && modelbodydiv !== null) {
            modelbodydiv.innerHTML = msg;
        }
    };
    CoreAppContainerComponent.prototype.setSelectedApplication = function (app) {
        var _this = this;
        var listAppProfiles = this.userProfile.appProfiles;
        if (listAppProfiles.length > 0) {
            this.selectedApplication = listAppProfiles.find(function (X) { return X.appName.toLowerCase() === app.toLowerCase(); });
            if (this.selectedApplication !== undefined) {
                this.removeContent();
                this.isAppSwitchSelected = false;
                this.defaultApp = this.selectedApplication.appName;
                this.loadApplication(this.selectedApplication);
                this.storage.setItem('app', this.selectedApplication.appName);
                var selectedAppDom = document.querySelector(this.selectedApplication.appName);
                var config = { childList: true };
                var observer_1 = new MutationObserver(function () {
                    _this.progressBarService.showHideProgressBar(false, _this.progressBarId);
                    observer_1.disconnect();
                });
                observer_1.observe(selectedAppDom, config);
                if ((this.activeRoute.snapshot.url.length > 0 && this.activeRoute.snapshot.url[0].path === 'sop-app') || (this.deepLinkingUrl !== undefined && this.deepLinkingUrl.startsWith('/sop-app'))) {
                    if (this.deepLinkingUrl !== undefined && this.deepLinkingUrl !== '') {
                        this.location.go(this.location.normalize(this.deepLinkingUrl));
                    }
                    else {
                        this.location.go(this.location.normalize(this.router.url));
                    }
                }
                else {
                    this.location.go(this.location.normalize(this.selectedApplication.defaultNavigationPath));
                }
            }
            else {
                this.router.navigate(['/login'], { queryParams: { app: app } });
                return;
            }
        }
    };
    CoreAppContainerComponent.prototype.showMessage = function (type, message) {
        var alert = {
            type: type,
            message: message,
            alertComponentId: this.alertComponentId
        };
        this.alertService.open(alert);
    };
    CoreAppContainerComponent.prototype.showToasterMessage = function (type, message) {
        if (this.activeToast === undefined ||
            (this.activeToast !== undefined &&
                (this.activeToast.message !== message ||
                    this.activeToast.toastRef.isInactive()))) {
            switch (type) {
                case AlertMessageType.Danger:
                    this.activeToast = this.toastr.error(message);
                    break;
                case AlertMessageType.Info:
                    this.activeToast = this.toastr.info(message);
                    break;
                case AlertMessageType.Warning:
                    this.activeToast = this.toastr.warning(message);
                    break;
                case AlertMessageType.Success:
                    this.activeToast = this.toastr.success(message);
                    break;
            }
        }
    };
    CoreAppContainerComponent.prototype.startTimer = function (timeoutMinutes) {
        if (timeoutMinutes < 0) {
            return new Observable(function (observer) { observer.next(Constants.MaxSessionCounterLimit); });
        }
        else {
            var dueTime = timeoutMinutes * Constants.Seconds * Constants.MilliSeconds;
            return timer(dueTime, Constants.MilliSeconds);
        }
    };
    tslib_1.__decorate([
        Select(AuthState),
        tslib_1.__metadata("design:type", Object)
    ], CoreAppContainerComponent.prototype, "user$", void 0);
    tslib_1.__decorate([
        Select(AuthState),
        tslib_1.__metadata("design:type", Object)
    ], CoreAppContainerComponent.prototype, "userAccessProfile$", void 0);
    return CoreAppContainerComponent;
}());
export { CoreAppContainerComponent };
