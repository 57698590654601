import * as i0 from "./csop-progressbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/asg-progress/asg-progress.ngfactory";
import * as i3 from "asg-progress";
import * as i4 from "./csop-progressbar.component";
var styles_CsopProgressbarComponent = [i0.styles];
var RenderType_CsopProgressbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CsopProgressbarComponent, data: {} });
export { RenderType_CsopProgressbarComponent as RenderType_CsopProgressbarComponent };
export function View_CsopProgressbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "asg-progress", [], null, null, null, i2.View_AsgProgressComponent_0, i2.RenderType_AsgProgressComponent)), i1.ɵdid(2, 4243456, [["csopProgress", 4]], 0, i3.AsgProgressComponent, [], { progressValuePercent: [0, "progressValuePercent"], progressWidth: [1, "progressWidth"], progressRawValue: [2, "progressRawValue"], progressRawMax: [3, "progressRawMax"], message: [4, "message"], showPercentLabel: [5, "showPercentLabel"], progType: [6, "progType"], spinnerSize: [7, "spinnerSize"], isModal: [8, "isModal"], wholePage: [9, "wholePage"], visible: [10, "visible"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.progressBar.progressValuePercent; var currVal_1 = _co.progressBar.progressWidth; var currVal_2 = _co.progressBar.progressRawValue; var currVal_3 = _co.progressBar.progressRawMax; var currVal_4 = _co.progressBar.message; var currVal_5 = _co.progressBar.showPercentLabel; var currVal_6 = _co.progressBar.progType; var currVal_7 = _co.progressBar.spinnerSize; var currVal_8 = _co.progressBar.isModal; var currVal_9 = _co.progressBar.wholePage; var currVal_10 = _co.progressBar.visible; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); }, null); }
export function View_CsopProgressbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "csop-lib-progressbar", [], null, null, null, View_CsopProgressbarComponent_0, RenderType_CsopProgressbarComponent)), i1.ɵdid(1, 49152, null, 0, i4.CsopProgressbarComponent, [], null, null)], null, null); }
var CsopProgressbarComponentNgFactory = i1.ɵccf("csop-lib-progressbar", i4.CsopProgressbarComponent, View_CsopProgressbarComponent_Host_0, { data: "data" }, {}, []);
export { CsopProgressbarComponentNgFactory as CsopProgressbarComponentNgFactory };
