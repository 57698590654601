import { CsopProgressBarType } from '../../types/enum/csop-progressbar-type.enum';
import { CsopProgressBarSize } from '../../types/enum/csop-progressbar-size.enum';
var CsopProgressbarComponent = (function () {
    function CsopProgressbarComponent() {
        this.progressBar = {
            isModal: false,
            message: '',
            progressbarComponentId: '',
            progType: CsopProgressBarType.Indeterminate,
            showPercentLabel: false,
            spinnerSize: CsopProgressBarSize.Small,
            visible: false,
            wholePage: false
        };
    }
    Object.defineProperty(CsopProgressbarComponent.prototype, "data", {
        set: function (progressbar) {
            this.progressBar = progressbar;
        },
        enumerable: true,
        configurable: true
    });
    return CsopProgressbarComponent;
}());
export { CsopProgressbarComponent };
