/* P r o p r i e t a r y N o t i c e */
/* Unpublished © 2020 Allscripts Healthcare, LLC and/or its affiliates. All Rights
Reserved.
*
* P r o p r i e t a r y N o t i c e: This software has been provided pursuant to a License Agreement, with Allscripts
Healthcare, LLC and/or its affiliates, containing restrictions on its use. This software contains valuable trade secrets
and proprietary information of Allscripts Healthcare, LLC and/or its affiliates and is protected by trade secret and
copyright law. This software may not be copied or distributed in any form or medium, disclosed to any third parties,
or used in any manner not provided for in said License Agreement except with prior written authorization from
Allscripts Healthcare, LLC and/or its affiliates. Notice to U.S. Government Users: This software is “Commercial
Computer Software.”
Allscripts Common Services Operations Portal is a trademark of Allscripts Healthcare, LLC and/or its affiliates.
*
*
*/
/* P r o p r i e t a r y N o t i c e */

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  SecurityContext,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { interval, Subscription } from 'rxjs';
import { ICsopAlertMessage } from 'projects/csop-lib/src/public-api';

@Component({
  templateUrl: './csop-alert.component.html',
  styleUrls: ['./csop-alert.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class CsopAlertComponent implements OnDestroy {
  @Input()
  public set alertmessage(alertmessage: ICsopAlertMessage) {
    this.alert = alertmessage;
    if (this.alert.autoCloseTimer !== undefined && this.alert.autoCloseTimer > 0) {
      this.timeoutSubscription = interval(this.alert.autoCloseTimer)
        .subscribe(
          () => {
            this.close();
          }
        );
    }
    // if rich text to be displayed, sanitize it for html context and assign it as SafeHtml
    if (this.alert.isRichText !== undefined && this.alert.isRichText === true) {
      const sanitizedText = this.sanitizer.sanitize(SecurityContext.HTML, this.alert.message);
      if (sanitizedText !== null && sanitizedText !== '') {
        // if the text is sanitized and not blank, use the original message for safe rich html
        this.richText = this.sanitizer.bypassSecurityTrustHtml(this.alert.message);
      }
    } else {
      this.richText = this.alert.message;
    }
  }

  public alert: ICsopAlertMessage;
  @Output()
  public closed = new EventEmitter<boolean>();
  // tslint:disable-next-line:no-uninitialized
  public richText: SafeHtml | string = '';
  private timeoutSubscription: Subscription | undefined;

  public constructor(private readonly sanitizer: DomSanitizer) {
    this.alert = {
      type: '',
      message: '',
      alertComponentId: '',
      autoCloseTimer: 0,
      isRichText: false
    };
  }

  public close() {
    this.closed.next(true);
  }

  public ngOnDestroy() {
    if (this.timeoutSubscription !== undefined) {
      this.timeoutSubscription.unsubscribe();
    }
  }
}
