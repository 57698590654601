import { CsopProgressBarType } from '../../types/enum/csop-progressbar-type.enum';
import { CsopProgressBarSize } from '../../types/enum/csop-progressbar-size.enum';
import * as i0 from "@angular/core";
var CsopProgressBarService = (function () {
    function CsopProgressBarService() {
    }
    CsopProgressBarService.prototype.showHide = function (progressBarData) {
        var containerElement = document.getElementById(progressBarData.progressbarComponentId);
        if (containerElement !== null) {
            var progressBarNode = containerElement.getElementsByTagName('asg-progressbar');
            if (progressBarNode.length > 0) {
                var progressbarEl = progressBarNode[0];
                progressbarEl.data = progressBarData;
            }
            else {
                var progressbarEl = document.createElement('asg-progressbar');
                progressbarEl.data = progressBarData;
                containerElement.appendChild(progressbarEl);
            }
        }
        else {
        }
    };
    CsopProgressBarService.prototype.showHideProgressBar = function (show, busyIndicatorPlaceholderId, wholePage, showPercentLabel, message) {
        if (wholePage === void 0) { wholePage = true; }
        if (showPercentLabel === void 0) { showPercentLabel = false; }
        if (message === void 0) { message = ''; }
        var progressBar = {
            isModal: false,
            message: message,
            progressbarComponentId: busyIndicatorPlaceholderId,
            progType: CsopProgressBarType.Indeterminate,
            showPercentLabel: showPercentLabel,
            spinnerSize: CsopProgressBarSize.Normal,
            visible: show,
            wholePage: wholePage
        };
        this.showHide(progressBar);
    };
    CsopProgressBarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CsopProgressBarService_Factory() { return new CsopProgressBarService(); }, token: CsopProgressBarService, providedIn: "root" });
    return CsopProgressBarService;
}());
export { CsopProgressBarService };
