/* P r o p r i e t a r y N o t i c e */
/* Unpublished © 2020 Allscripts Healthcare, LLC and/or its affiliates. All Rights
Reserved.
*
* P r o p r i e t a r y N o t i c e: This software has been provided pursuant to a License Agreement, with Allscripts
Healthcare, LLC and/or its affiliates, containing restrictions on its use. This software contains valuable trade secrets
and proprietary information of Allscripts Healthcare, LLC and/or its affiliates and is protected by trade secret and
copyright law. This software may not be copied or distributed in any form or medium, disclosed to any third parties,
or used in any manner not provided for in said License Agreement except with prior written authorization from
Allscripts Healthcare, LLC and/or its affiliates. Notice to U.S. Government Users: This software is “Commercial
Computer Software.”
Allscripts Common Services Operations Portal is a trademark of Allscripts Healthcare, LLC and/or its affiliates.
*
*
*/
/* P r o p r i e t a r y N o t i c e */

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import {
  DialogDetail,
  ICsopDialogBase,
  ICsopDialogCloseContent
} from '../../../public-api';

import { CsopDialogInjectionDirective } from './csop-dialog-injection.directive';

@Component({
  selector: 'csop-modal',
  templateUrl: './csop-modal.component.html',
  styleUrls: ['./csop-modal.component.scss']
})
export class CsopModalComponent implements AfterViewInit, OnDestroy {

  public btnCancelText!: string;
  public btnOkText!: string;
  public componentRef!: ComponentRef<any>; // tslint:disable-line
  // tslint:disable-next-line:no-uninitialized
  @ViewChild(CsopDialogInjectionDirective, { static: false })
  public csopDialogInjection!: CsopDialogInjectionDirective;
  // tslint:disable-next-line:no-uninitialized
  public dialogDetail!: DialogDetail;
  public event!: MouseEvent;
  public message = '';
  public showCancelButton = true;
  public showOkButton = true;
  public title = '';
  // private variables
  // tslint:disable-next-line:no-uninitialized
  private eventSubscription!: Subscription;

  public constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly changeDetection: ChangeDetectorRef,
    public activeModalSvc: NgbActiveModal
  ) {
    this.btnCancelText = 'Cancel';
    this.btnOkText = 'OK';
  }

  public accept(bypassValidation: boolean = false) {
    if (bypassValidation === false &&
      this.dialogDetail !== undefined && // tslint:disable-line: strict-type-predicates
      this.dialogDetail.enableValidation === true) {
      (<ICsopDialogBase>this.componentRef.instance).validateOnClose(
      ).catch(err => {
        console.error(err);
      })
        .then(
          res => {
            if (res === true) {
              this.closeDialog(true);
            }
          }
        );
    } else {
      this.closeDialog(true);
    }
  }

  public decline(returnData: boolean = false) {
    // NOTE: DO NOT return data object when user closes dialog with cancel button
    this.closeDialog(false, returnData);
  }

  public dismiss() {
    // NOTE: DO NOT return data object when user closes dialog with cross button
    this.activeModalSvc.dismiss(false);
  }

  public ngAfterViewInit() {
    if (this.dialogDetail !== undefined) { // tslint:disable-line: strict-type-predicates
      this.loadChildComponent();
      this.changeDetection.detectChanges();
    }
  }

  public ngOnDestroy() {
    if (this.componentRef !== undefined) { // tslint:disable-line: strict-type-predicates
      this.componentRef.destroy();
    }
    if (this.eventSubscription !== undefined) { // tslint:disable-line: strict-type-predicates
      this.eventSubscription.unsubscribe();
    }
  }

  private closeDialog(state: boolean, returnDataOnCancel: boolean = true) {
    if (returnDataOnCancel === true) {
      // tslint:disable-next-line: strict-type-predicates
      if (this.dialogDetail !== undefined) {

        if (this.dialogDetail.data === undefined) {
          this.activeModalSvc.close({
            data: this.componentRef.instance.data,
            response: state
          });
        } else {
            this.activeModalSvc.close({
            data: this.dialogDetail.data,
            response: state
          });
        }
      } else {
        this.activeModalSvc.close(state);
      }
    } else {
      this.activeModalSvc.close(state);
    }
  }

  private loadChildComponent() {
    // assign properties to base modal component
    this.title = this.dialogDetail.title;
    this.btnCancelText = this.dialogDetail.btnCancelText;
    this.btnOkText = this.dialogDetail.btnOkText;
    this.showOkButton = this.dialogDetail.showOkButton;
    this.showCancelButton = this.dialogDetail.showCancelButton;
    this.message = this.dialogDetail.message;
    // inject custom dialog component
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.dialogDetail.component);
    const viewContainerRef = this.csopDialogInjection.viewContainerRef;
    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent(componentFactory);

    // Initialize custom dialog component
    (<ICsopDialogBase>this.componentRef.instance).data = this.dialogDetail.data;
    this.eventSubscription = (<ICsopDialogBase>this.componentRef.instance).dialogClosed.subscribe(
      (dialogCloseContent: ICsopDialogCloseContent) => {
        if (dialogCloseContent.state === true) {
          // bypass validation as it is already done
          this.accept(true);
        } else {
          this.decline(dialogCloseContent.returnDataOnCancel);
        }
      }
    );
  }

}
