import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Action, Selector, State, } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AuthStateModel, } from '../../model';
import { AzureAdService } from '../../../../../projects/csop-lib/src/lib/services/azureservices/azure-ad-service';
import { StoreUserProfile } from './store-userprofile.action';
import { Login } from './auth.login.action';
import { Logout } from './auth.logout.action';
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
    })
};
var AuthState = (function () {
    function AuthState(azureAdService, http) {
        this.azureAdService = azureAdService;
        this.http = http;
        this.apiUrl = 'api/user';
    }
    AuthState.token = function (state) {
        return state.token;
    };
    AuthState.userProfile = function (state) {
        return state;
    };
    AuthState.prototype.login = function (ctx, action) {
        return this.http.post("" + this.apiUrl, action.payload, httpOptions)
            .pipe(tap(function (result) {
            ctx.patchState({
                token: result,
                username: action.payload.userName
            });
        }));
    };
    AuthState.prototype.logout = function (ctx) {
        this.azureAdService.logout();
        ctx.getState();
        ctx.setState({});
    };
    AuthState.prototype.storeUserProfile = function (ctx, action) {
        return ctx.patchState({
            appProfiles: action.userAccessProfile.appProfiles,
            userName: action.userAccessProfile.userName
        });
    };
    tslib_1.__decorate([
        Action(Login),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, Login]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AuthState.prototype, "login", null);
    tslib_1.__decorate([
        Action(Logout),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AuthState.prototype, "logout", null);
    tslib_1.__decorate([
        Action(StoreUserProfile),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, StoreUserProfile]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AuthState.prototype, "storeUserProfile", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AuthStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AuthState, "token", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Array]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AuthState, "userProfile", null);
    AuthState = tslib_1.__decorate([
        State({
            name: 'auth'
        }),
        State({
            name: 'userProfile'
        }),
        tslib_1.__metadata("design:paramtypes", [AzureAdService,
            HttpClient])
    ], AuthState);
    return AuthState;
}());
export { AuthState };
