<!-- P r o p r i e t a r y N o t i c e */
/* Unpublished © 2020 Allscripts Healthcare, LLC and/or its affiliates. All Rights
Reserved.
*
* P r o p r i e t a r y N o t i c e: This software has been provided pursuant to a License Agreement, with Allscripts
Healthcare, LLC and/or its affiliates, containing restrictions on its use. This software contains valuable trade secrets
and proprietary information of Allscripts Healthcare, LLC and/or its affiliates and is protected by trade secret and
copyright law. This software may not be copied or distributed in any form or medium, disclosed to any third parties,
or used in any manner not provided for in said License Agreement except with prior written authorization from
Allscripts Healthcare, LLC and/or its affiliates. Notice to U.S. Government Users: This software is “Commercial
Computer Software.”
Allscripts Common Services Operations Portal is a trademark of Allscripts Healthcare, LLC and/or its affiliates.
*
*
*/
/* P r o p r i e t a r y N o t i c e -->

<div class="mainDiv">
  <div class="row">
    <div class="col-md-3" id="md-3"></div>
    <div class="col-md-6" id="md-6">
      <div class="text-center mb-50">
        <span>Common Service Operational Portal</span>
      </div>
      <br>
      <div class="row">
        <ul class="list-group list-group-horizontal">
          <li *ngFor="let app of application | slice:0:4" class="card" (click)="onAppSelected(app.appName)">
            <img src="../../assets/icons/{{app.appIcon}}">
            <br>
            <p>{{app.appTitle}}</p>
        </ul>
      </div>
      <div class="row">
        <ul class="list-group list-group-horizontal">
          <li *ngFor="let app of application | slice:4:8" class="card last-row" (click)="onAppSelected(app.appName)">
            <img src="../../assets/icons/{{app.appIcon}}">
            <br>
            <p>{{app.appTitle}}</p>
        </ul>
      </div>
    </div>
    <div class="col-md-3" id="md-3"></div>
  </div>
</div>
<div #divIDP>

</div>
<div>
  <p class="version">{{version}}</p>
  <p class="note">Note: Use Chrome, for best responsive user interface use 1920X1080 display resolution</p>
  <p class="copy-right d-none d-md-block">
    © 2020 Allscripts Healthcare, LLC and/or its affiliates. All Rights Reserved. This software is the confidential and
    proprietary information of Allscripts Healthcare, LLC and/or its affiliates (hereinafter “Allscripts Healthcare,
    LLC”) and is protected by trade secret and copyright law. This software and all related rights are the exclusive
    property of Allscripts Healthcare, LLC. All use, modification, reproduction, release, performance, display and/or
    disclosure is governed by the license terms of Allscripts Healthcare, LLC. Notice to U.S. Government Users: This
    medium and its contents constitute “Commercial Computer Software” within the meaning of FAR 2.101 (November 2007),
    FAR Part 12.212 (October 1995), FAR 27.405-3 (November 2007), DFARS Part 227.7202 (June 1995) and DFARS 252.227-7014
    (a) (June 1995). Manufacturer is Allscripts Healthcare, LLC, 222 Merchandise Mart Plaza, Suite #2024, Chicago, IL
    60654. Allscripts Common Services Operations Portal is a trademark of Allscripts Healthcare, LLC.
  </p>

</div>