import { ToastrService } from 'ngx-toastr';
import { ToasterPosition } from '../../types/enum/toastr-position.enum';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
var ToasterService = (function () {
    function ToasterService(toastr) {
        this.toastr = toastr;
    }
    ToasterService.prototype.error = function (message, title, positionClass) {
        if (positionClass === void 0) { positionClass = ToasterPosition.TopCenter; }
        return this.toastr.error(message, title, { positionClass: positionClass });
    };
    ToasterService.prototype.info = function (message, title, positionClass) {
        if (positionClass === void 0) { positionClass = ToasterPosition.TopCenter; }
        return this.toastr.info(message, title, { positionClass: positionClass });
    };
    ToasterService.prototype.success = function (message, title, positionClass) {
        if (positionClass === void 0) { positionClass = ToasterPosition.TopCenter; }
        return this.toastr.success(message, title, { positionClass: positionClass });
    };
    ToasterService.prototype.warning = function (message, title, positionClass) {
        if (positionClass === void 0) { positionClass = ToasterPosition.TopCenter; }
        return this.toastr.warning(message, title, { positionClass: positionClass });
    };
    ToasterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToasterService_Factory() { return new ToasterService(i0.ɵɵinject(i1.ToastrService)); }, token: ToasterService, providedIn: "root" });
    return ToasterService;
}());
export { ToasterService };
