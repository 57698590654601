import * as i0 from "./login-passive-federation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./login-passive-federation.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../projects/csop-lib/src/lib/services/configservice/configuration.service";
import * as i5 from "../../../projects/csop-lib/src/lib/services/shieldpassivefederationservice/shield-passive-federation-service";
var styles_LoginPassiveFederationComponent = [i0.styles];
var RenderType_LoginPassiveFederationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginPassiveFederationComponent, data: {} });
export { RenderType_LoginPassiveFederationComponent as RenderType_LoginPassiveFederationComponent };
export function View_LoginPassiveFederationComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_LoginPassiveFederationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-passive-federation", [], null, null, null, View_LoginPassiveFederationComponent_0, RenderType_LoginPassiveFederationComponent)), i1.ɵdid(1, 114688, null, 0, i2.LoginPassiveFederationComponent, [i3.Router, i4.ConfigService, i5.ShieldPassiveFederationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginPassiveFederationComponentNgFactory = i1.ɵccf("app-login-passive-federation", i2.LoginPassiveFederationComponent, View_LoginPassiveFederationComponent_Host_0, {}, {}, []);
export { LoginPassiveFederationComponentNgFactory as LoginPassiveFederationComponentNgFactory };
