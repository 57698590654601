import { HttpHeaders } from '@angular/common/http';
import { StorageService } from 'src/app/core/services';
import { Constants, DataRequest, HttpActionType } from 'projects/csop-lib/src/public-api';
import { GenericDataService } from '../dataservice/generic-data.service.';
import { Applications } from '../../../../../../src/app/core/constants';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../src/app/core/services/storage.service";
import * as i2 from "../dataservice/generic-data.service.";
var ShieldPassiveFederationService = (function () {
    function ShieldPassiveFederationService(storage, genericDataService) {
        this.storage = storage;
        this.genericDataService = genericDataService;
        this.idpResponse = '';
        this.sopBrowserSessionTimeout = Constants.DefaultSOPBrowserSessionTimeout;
    }
    ShieldPassiveFederationService.prototype.checkIdentityAccessDetails = function () {
        var options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        return this.genericDataService.execute(new DataRequest(HttpActionType.POST, Constants.shieldPassiveFederation, Constants.checkIdentityAccessDetails, undefined, undefined, undefined, undefined, options));
    };
    ShieldPassiveFederationService.prototype.GetRolesAndPermission = function () {
        var RelayState = this.storage.getItem(this.storage.getItem('app') + '-RS');
        var options = {
            headers: new HttpHeaders().set('RelayState', btoa(RelayState))
        };
        return this.genericDataService.execute(new DataRequest(HttpActionType.GET, Constants.shieldPassiveFederation, Constants.getUserAuthenticationDetails, undefined, undefined, undefined, undefined, options));
    };
    ShieldPassiveFederationService.prototype.isUserAuthenticated = function () {
        return this.storage.getItem(Applications.Sop + '-RS') !== '';
    };
    ShieldPassiveFederationService.prototype.logout = function () {
        var RelayState = this.storage.getItem(Applications.Sop + '-RS');
        var options = {
            headers: new HttpHeaders().set('RelayState', btoa(RelayState))
        };
        return this.genericDataService.execute(new DataRequest(HttpActionType.GET, Constants.shieldPassiveFederation, Constants.removeUserDetails, undefined, undefined, undefined, undefined, options));
    };
    ShieldPassiveFederationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ShieldPassiveFederationService_Factory() { return new ShieldPassiveFederationService(i0.ɵɵinject(i1.StorageService), i0.ɵɵinject(i2.GenericDataService)); }, token: ShieldPassiveFederationService, providedIn: "root" });
    return ShieldPassiveFederationService;
}());
export { ShieldPassiveFederationService };
