import { AlertConstants } from '../../types/constants';
import * as i0 from "@angular/core";
var CsopAlertService = (function () {
    function CsopAlertService() {
    }
    CsopAlertService.prototype.close = function (alertComponentId) {
        var content = document.getElementById(alertComponentId);
        if (content !== null) {
            var alertNodes = content.getElementsByTagName('asg-alert');
            if (alertNodes.length === 0) {
                alertNodes =
                    content.getElementsByTagName('csop-inline-alert');
            }
            while (alertNodes.length > 0) {
                alertNodes[0].remove();
            }
        }
        else {
        }
    };
    CsopAlertService.prototype.open = function (alert, closeExisting) {
        if (closeExisting === void 0) { closeExisting = false; }
        var content = document.getElementById(alert.alertComponentId);
        if (content !== null) {
            if (closeExisting === true) {
                this.close(alert.alertComponentId);
            }
            var alertElement = this.createAlertElement(false);
            this.show(alertElement, content, alert);
        }
        else {
        }
    };
    CsopAlertService.prototype.showInline = function (alert) {
        var content = document.getElementById(alert.alertComponentId);
        if (content !== null) {
            this.close(alert.alertComponentId);
            var alertElement = this.createAlertElement(true);
            this.show(alertElement, content, alert);
        }
        else {
        }
    };
    CsopAlertService.prototype.createAlertElement = function (inlineAlert) {
        if (inlineAlert) {
            return document.createElement('csop-inline-alert');
        }
        else {
            return document.createElement('asg-alert');
        }
    };
    CsopAlertService.prototype.show = function (popupEl, content, alert) {
        popupEl.addEventListener('closed', function () { return content.removeChild(popupEl); });
        popupEl.alertmessage = alert;
        if (alert.decisionElement !== undefined) {
            alert.decisionElement.setAttribute('slot', AlertConstants.alertMessageResolveSlotId);
            popupEl.appendChild(alert.decisionElement);
        }
        content.appendChild(popupEl);
    };
    CsopAlertService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CsopAlertService_Factory() { return new CsopAlertService(); }, token: CsopAlertService, providedIn: "root" });
    return CsopAlertService;
}());
export { CsopAlertService };
