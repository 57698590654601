import * as i0 from "./csop-alert.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "./csop-alert.component";
import * as i5 from "@angular/platform-browser";
var styles_CsopAlertComponent = [i0.styles];
var RenderType_CsopAlertComponent = i1.ɵcrt({ encapsulation: 3, styles: styles_CsopAlertComponent, data: {} });
export { RenderType_CsopAlertComponent as RenderType_CsopAlertComponent };
export function View_CsopAlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ngb-alert", [["class", "alert"], ["role", "alert"]], [[2, "alert-dismissible", null]], [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NgbAlert_0, i2.RenderType_NgbAlert)), i1.ɵdid(2, 638976, null, 0, i3.NgbAlert, [i3.NgbAlertConfig, i1.Renderer2, i1.ElementRef], { dismissible: [0, "dismissible"], type: [1, "type"] }, { close: "close" }), (_l()(), i1.ɵeld(3, 0, null, 0, 0, "div", [["class", "csop-alert"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, 0, 0, "slot", [["name", "message-to-resolve"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = true; var currVal_2 = _co.alert.type; _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).dismissible; _ck(_v, 1, 0, currVal_0); var currVal_3 = _co.richText; _ck(_v, 3, 0, currVal_3); }); }
export function View_CsopAlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_CsopAlertComponent_0, RenderType_CsopAlertComponent)), i1.ɵdid(1, 180224, null, 0, i4.CsopAlertComponent, [i5.DomSanitizer], null, null)], null, null); }
var CsopAlertComponentNgFactory = i1.ɵccf("ng-component", i4.CsopAlertComponent, View_CsopAlertComponent_Host_0, { alertmessage: "alertmessage" }, { closed: "closed" }, []);
export { CsopAlertComponentNgFactory as CsopAlertComponentNgFactory };
