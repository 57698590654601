import { fromEvent, merge, Subject, timer } from 'rxjs';
import { Constants } from '../../../public-api';
import * as i0 from "@angular/core";
var CsopUserIdleService = (function () {
    function CsopUserIdleService() {
        this.expired$ = new Subject();
        this.observeUserActivity = new Subject();
        this.schedulerTime = Constants.DefaultSessionSchedulerTime;
    }
    CsopUserIdleService.prototype.resetTimer = function () {
        this.timer$.unsubscribe();
        this.startTimer();
    };
    CsopUserIdleService.prototype.startWatching = function (timeOutSeconds, authenticationType) {
        var _this = this;
        this.idle$ = merge(fromEvent(document, 'mousemove'), fromEvent(document, 'click'), fromEvent(document, 'mousedown'), fromEvent(document, 'keypress'), fromEvent(document, 'keyup'), fromEvent(document, 'DOMMouseScroll'), fromEvent(document, 'mousewheel'), fromEvent(document, 'touchmove'), fromEvent(document, 'MSPointerMove'), fromEvent(window, 'mousemove'), fromEvent(window, 'resize'));
        this.timeOutMilliSeconds = timeOutSeconds * 1000;
        this.idleSubscription = this.idle$.subscribe(function (_res) {
            if (_this.schedulerTime < 0) {
                _this.observeUserActivity.next(authenticationType);
            }
        });
        this.startTimer();
        return this.expired$;
    };
    CsopUserIdleService.prototype.stopTimer = function () {
        this.timer$.unsubscribe();
        this.idleSubscription.unsubscribe();
    };
    CsopUserIdleService.prototype.startTimer = function () {
        var _this = this;
        this.timer$ = timer(this.timeOutMilliSeconds, 1000).subscribe(function (_res) {
            _this.schedulerTime = _res;
            _this.expired$.next(_res);
        });
    };
    CsopUserIdleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CsopUserIdleService_Factory() { return new CsopUserIdleService(); }, token: CsopUserIdleService, providedIn: "root" });
    return CsopUserIdleService;
}());
export { CsopUserIdleService };
