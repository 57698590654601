/* P r o p r i e t a r y N o t i c e */
/* Unpublished © 2020 Allscripts Healthcare, LLC and/or its affiliates. All Rights
Reserved.
*
* P r o p r i e t a r y N o t i c e: This software has been provided pursuant to a License Agreement, with Allscripts
Healthcare, LLC and/or its affiliates, containing restrictions on its use. This software contains valuable trade secrets
and proprietary information of Allscripts Healthcare, LLC and/or its affiliates and is protected by trade secret and
copyright law. This software may not be copied or distributed in any form or medium, disclosed to any third parties,
or used in any manner not provided for in said License Agreement except with prior written authorization from
Allscripts Healthcare, LLC and/or its affiliates. Notice to U.S. Government Users: This software is “Commercial
Computer Software.”
Allscripts Common Services Operations Portal™ is a trademark of Allscripts Healthcare, LLC and/or its affiliates.
*
*
*/
/* P r o p r i e t a r y N o t i c e */
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy
} from '@angular/common';
import {
  NavigationStart,
  Router,
  ActivatedRoute,
  // NavigationStart,
} from '@angular/router';
import {
  fromEvent,
  Observable,
  Subscription,
  timer,
} from 'rxjs';
import {
  Store,
  Select
} from '@ngxs/store';
import { ActiveToast } from 'ngx-toastr';

import { TokenManagerService } from '../../../projects/csop-lib/src/lib/services/tokenmanagerservice/tokenmanager.service';
import { AuthState } from '../core/stores/Auth/auth.state';
import {
  AppProfile,
  AuthScopeInformation,
  AuthStateModel,
  UserAccessProfile,
} from '../core/model';
import {
  CoreConstants,
  Applications,
} from '../core/constants';
import { StoreUserProfile } from '../core/stores/Auth/store-userprofile.action';
import {
  ProfileRoleHelper,
  SharedFunctions
} from '../core/helpers';
import { StateService } from '../state.service';
import { StorageService } from '../core/services/storage.service';
import { CsopUserIdleService } from '../../../projects/csop-lib/src/lib/services/userIdleService/csop-user-idle-service';
import { CsopSignalRService } from '../../../projects/csop-lib/src/lib/services/signalRservice/csop-signalr.service';
import { AlertMessageType } from '../../../projects/csop-lib/src/lib/types/enum/alert-message-type.enum';
import { ConfigService } from '../../../projects/csop-lib/src/lib/services/configservice/configuration.service';
import { LoginService } from '../../../projects/csop-lib/src/lib/services/loginservices/login.service';
import { ToasterService } from '../../../projects/csop-lib/src/lib/services/toastrservice/toastr.service';
import { AzureAdService } from '../../../projects/csop-lib/src/lib/services/azureservices/azure-ad-service';
import {
  ShieldPassiveFederationService
} from '../../../projects/csop-lib/src/lib/services/shieldpassivefederationservice/shield-passive-federation-service';
import {
  IAppSettings,
  AuthenticationType,
  CsopAlertMessageType,
  CsopAlertService,
  CoreEvent,
  CsopEventType,
  ICsopAlertMessage,
  CsopDialogService,
  CsopSwapEventType,
  Constants,
  CsopProgressBarService,
  CsopLibSharedFunctions,
  ISetting,
  IAuthProviders,
} from '../../../projects/csop-lib/src/public-api';
import { Logout } from '../core/stores/Auth/auth.logout.action';
import { UserProfileService } from '../../../projects/csop-lib/src/lib/services/userprofileservice/userprofile.service';

@Component({
  selector: 'app-app-container',
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy }],
  templateUrl: './core-app-container.component.html',
  styleUrls: ['./core-app-container.component.scss']
})

export class CoreAppContainerComponent implements OnInit, OnDestroy, AfterViewInit {
  public readonly alertComponentId = 'csop-app-container-alert';
  public application: Array<IAppSettings> = [];
  public appName: string;
  public appTitle: string;

  public deepLinkingUrl = '';
  public defaultApp: string;

  public isAppSwitchSelected = false;
  public progressBarId = 'csop-app-container-progressbar';
  // tslint:disable-next-line:no-any
  public result: any;

  public swappedApp = '';
  public targetedApp = Constants.empty;
  public targetedAppSettings: IAppSettings | undefined;
  public toggle = false;
  @Select(AuthState) public user$: Observable<AuthStateModel> | undefined;
  @Select(AuthState) public userAccessProfile$: Observable<Array<UserAccessProfile>> | undefined;
  public userName = '';
  public userProfile: UserAccessProfile;
  private activeToast!: ActiveToast<any> | undefined; // tslint:disable-line:no-any
  private readonly appNotification: Observable<Event>;
  // tslint:disable-next-line:no-uninitialized
  private appNotificationSubscription!: Subscription;
  private readonly appRequest: Observable<Event>;
  // tslint:disable-next-line:no-uninitialized
  private appRequestSubscription!: Subscription;
  private currentInteration = 0;
  private inactivityTimeout = Constants.inactivityTimeout;
  private reload = true;
  private selectedApplication!: AppProfile | undefined;
  private sopSessionSchedulerTime = Constants.DefaultSessionSchedulerTime;
  // tslint:disable-next-line:no-any
  private sopSessionTimer$: any; // tslint:disable-line:no-uninitialized
  private targetedUrlIsAzureAd = false;

  public constructor(
    private readonly alertService: CsopAlertService,
    private readonly azureAdService: AzureAdService,
    private readonly configService: ConfigService,
    private readonly dialogService: CsopDialogService,
    private readonly location: Location,
    private readonly loginService: LoginService,
    private readonly profileRoleHelper: ProfileRoleHelper,
    private readonly router: Router,
    private readonly stateSvc: StateService,
    private readonly store: Store,
    private readonly storage: StorageService,
    private readonly toastr: ToasterService,
    private readonly signalrService: CsopSignalRService,
    private readonly progressBarService: CsopProgressBarService,
    protected http: HttpClient,
    private readonly shieldPassiveFedSvc: ShieldPassiveFederationService,
    private readonly csopUserIdleSvc: CsopUserIdleService,
    private readonly activeRoute: ActivatedRoute,
    private readonly tokenManagerService: TokenManagerService,
    private readonly userProfileService: UserProfileService,
  ) {
    this.appRequest = fromEvent(document, CsopEventType[CsopEventType.Request]);
    this.appNotification = fromEvent(document, CsopEventType[CsopEventType.Notify]);
    this.userProfile = new UserAccessProfile('', []);
    this.defaultApp = CoreConstants.empty;
    this.appTitle = CoreConstants.empty;
    this.appName = CoreConstants.empty;
    if (!this.storage.getKey('ukey')) { // tslint:disable-line:strict-boolean-expressions
      this.storage.storeKey(JSON.stringify(SharedFunctions.makeRandomString(CoreConstants.keyLength)));
    }
    this.application = this.configService.config.csopsettings.applications.sort((a, b) => (a.renderingOrder > b.renderingOrder) ? 1 : -1);

    this.deepLinkingUrl = this.storage.getItem('deepLinkingUrl') !== '' ? this.storage.getItem('deepLinkingUrl') : '/';

    this.router.events.subscribe((event: any) => { // tslint:disable-line:no-any
      if (event instanceof NavigationStart) {
        this.targetedUrlIsAzureAd = event.url.includes('azureAd') ? true : false;
      }
    });

  }

  public closeSidenav() {
    this.toggle = !this.toggle;
  }

  public logout() {
    this.removeContent();
    if (this.targetedAppSettings !== undefined) {
      if (this.targetedAppSettings.authenticationMechanism.name === AuthenticationType.AzureAd
        && this.azureAdService.isUserAuthenticated()) {
        if (this.selectedApplication !== undefined) {
          this.azureAdService.selectedApplication = this.selectedApplication.appName;
        } else {
          this.azureAdService.selectedApplication = this.storage.getItem(Constants.app);
        }
        this.targetedUrlIsAzureAd = true;
        this.store.dispatch(new Logout());
      } else if (this.targetedAppSettings.authenticationMechanism.name === AuthenticationType.ShieldPassiveFederation) {
        this.shieldPassiveFedSvc.logout().subscribe(() => {
          this.storage.clear();
          this.loginService.redirectToAppLauncher();
        }, (error) => {
          console.error(error);
        });
      } else {
        this.storage.clear();
        this.loginService.redirectToAppLauncher();
      }
    }
  }

  public ngAfterViewInit() {
    this.progressBarService.showHideProgressBar(true, this.progressBarId);
  }

  public ngOnDestroy() {
    const content = document.getElementsByTagName('app-app-container');
    if (content !== null) { // tslint:disable-line:strict-type-predicates
      content[0].remove();
    }
    if (this.appNotificationSubscription !== undefined) { // tslint:disable-line: strict-type-predicates
      this.appRequestSubscription.unsubscribe();
    }

    if (this.appRequestSubscription !== undefined) { // tslint:disable-line: strict-type-predicates
      this.appRequestSubscription.unsubscribe();
    }
  }

  public async ngOnInit() {
    this.isAppSwitchSelected = false;
    this.targetedUrlIsAzureAd = false;
    this.storage.setItem(Constants.sessionTime, Date.now().toString());
    this.defaultApp = this.storage.getItem(Constants.app);
    const intialSelectedApp = this.application.find((app) => app.appName === this.defaultApp);
    let authenticationProvider = '';
    if (intialSelectedApp !== undefined) {
      this.appTitle = intialSelectedApp.appTitle;
      this.appName = intialSelectedApp.appName;
      this.targetedAppSettings = this.getAppSetting(intialSelectedApp.appName);
      authenticationProvider = intialSelectedApp.authenticationMechanism.name;
    }
    this.detectUserAction();

    this.http.get('SessionStart?appName=' + this.appName, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).subscribe((res) => {
      this.inactivityTimeout = Number(res);
      let maxIterationRequire = 1;
      console.log('Configure Session Timeout = ', this.inactivityTimeout);
      if (intialSelectedApp !== undefined && authenticationProvider === AuthenticationType.Shield) {

        // As Shiled Provide 20 min of session timeout, we are refreshing it at 19 min
        // tslint:disable-next-line:no-magic-numbers
        maxIterationRequire = Math.ceil(this.inactivityTimeout / 18);

        if (maxIterationRequire === 1) {
          this.currentInteration = maxIterationRequire + 1;
        } else {
          // tslint:disable-next-line:no-magic-numbers
          this.inactivityTimeout = 18;
        }
        console.log('Max Iteration Require = ', maxIterationRequire);
      }

      console.log('AuthenticationType', authenticationProvider);
      // tslint:disable-next-line:no-magic-numbers
      this.csopUserIdleSvc.startWatching((this.inactivityTimeout - 1) * 60,
        authenticationProvider as AuthenticationType).subscribe(async (inactivitytimer: number) => {
          // const modalWindow = document.querySelector('ngb-modal-window') as HTMLElement;
          // // tslint:disable-next-line:strict-boolean-expressions
          // if (modalWindow) {
          //   this.csopUserIdleSvc.stopTimer();
          // } else {

          console.log('currentInteration', this.currentInteration);
          console.log('maxIterationRequire', maxIterationRequire);

          this.currentInteration = this.currentInteration + 1;
          if (this.currentInteration <= maxIterationRequire && authenticationProvider === AuthenticationType.Shield) {
            // Call to refresh Token
            console.log('Current Iteration Count = ', this.currentInteration);
            console.log('Starting of Get Refresh Token');
            this.refreshShieldToken(this.appName);
            console.log('End Calling to Refresh Token. ', new Date().toString());
            this.csopUserIdleSvc.schedulerTime = Constants.DefaultSessionSchedulerTime;
            this.csopUserIdleSvc.resetTimer();
          } else {
            console.log('csopUserIdleSvc - schedulerTime:', this.csopUserIdleSvc.schedulerTime);
            // If SOP Refresh aup token is being displayed then don't open Inactivity pop up
            if (this.csopUserIdleSvc.schedulerTime === 0 && this.sopSessionSchedulerTime > Constants.DefaultSessionSchedulerTime) {
              this.csopUserIdleSvc.schedulerTime = Constants.DefaultSessionSchedulerTime;
              this.csopUserIdleSvc.stopTimer();
              inactivitytimer = Constants.DefaultSessionSchedulerTime;
            }
            console.log('startWatching - inactivitytimer:', inactivitytimer);
            // tslint:disable-next-line:no-magic-numbers
            if (inactivitytimer === 59 && this.sopSessionSchedulerTime === Constants.DefaultSessionSchedulerTime) {
              this.logout();
            }
            this.setMsgForSessionTimeoutWarning(inactivitytimer);
            if (inactivitytimer === 0 && this.sopSessionSchedulerTime === Constants.DefaultSessionSchedulerTime) {
              this.result = <boolean>await this.dialogService.openConfirmDialog(
                CoreConstants.confirmationMessage,
                'You will be logged out in 59 seconds. \n Do you want to stay signed in ?',
                CoreConstants.dlgBtnYes,
                CoreConstants.dlgBtnNo
              );
              if (this.result === true) {
                this.csopUserIdleSvc.schedulerTime = Constants.DefaultSessionSchedulerTime;
                this.currentInteration = 0;
                this.csopUserIdleSvc.resetTimer();
                const appSetting = this.getAppSetting(this.appName);
                if (appSetting !== undefined) {
                  const authProvider = appSetting.authenticationMechanism;
                  if (authProvider.name === AuthenticationType.ShieldPassiveFederation) {
                    this.refreshSOPSession();
                    // this.resetSOPBrowserSessionTimeout();
                  } else if (authProvider.name === AuthenticationType.Shield) {
                    this.refreshShieldToken(this.appName);
                  } else {
                    this.continueSession();
                  }
                }
              } else {
                this.logout();
              }
            }
          }
        });
    });

    // set session status
    this.storage.setItem('sessionStatus', 'true');
    const delay = 100;
    SharedFunctions.sleep(delay);

    if (intialSelectedApp !== undefined) {
      if (intialSelectedApp.authenticationMechanism.name === AuthenticationType.AzureAd) {
        if (this.azureAdService.isUserAuthenticated()) {
          if (this.storage.getItem(CoreConstants.isUserLoggedIn) === Constants.empty) {
            this.storage.setItem(CoreConstants.isUserLoggedIn, 'true');
            this.signalrService.startConnection();
          }
          this.processAzureAdRequest();
        }
      } else if (intialSelectedApp.authenticationMechanism.name === AuthenticationType.ShieldPassiveFederation) {
        this.userName = this.storage.getItem(CoreConstants.email);
        if (this.storage.getItem(CoreConstants.isUserLoggedIn) === Constants.empty) {
          this.storage.setItem(CoreConstants.isUserLoggedIn, 'true');
          this.signalrService.startConnection();
        }
        this.userProfile.appProfiles = this.profileRoleHelper.getApplicationProfiles();
        this.setSelectedApplication(this.storage.getItem('app'));
        console.log('Constants DefaultSOPBrowserSessionTimeout:', Constants.DefaultSOPBrowserSessionTimeout);
        console.log('shieldPassiveFedSvc - sopBrowserSessionTimeout:', this.shieldPassiveFedSvc.sopBrowserSessionTimeout);
        if (this.shieldPassiveFedSvc.sopBrowserSessionTimeout !== Constants.DefaultSOPBrowserSessionTimeout) {
          this.startBrowserSessionTimer(this.shieldPassiveFedSvc.sopBrowserSessionTimeout);
        }
      } else {
        if (this.loginService.isUserAuthenticated) {
          this.userName = this.storage.getItem('fullName');
          if (this.storage.getItem(CoreConstants.isUserLoggedIn) === Constants.empty) {
            this.storage.setItem(CoreConstants.isUserLoggedIn, 'true');
            this.signalrService.startConnection();
          }
          this.userProfile.appProfiles = this.profileRoleHelper.getApplicationProfiles();
          this.setSelectedApplication(this.storage.getItem('app'));
        } else {
          this.toastr.warning('User is not authorized to access any application.');
        }
      }
    }
  }

  public async onAppChange(app: IAppSettings) {
    const sameApp = this.storage.getItem('app');

    if (sameApp !== app.appName) {
      this.toggle = false;
      const result = <boolean>await this.dialogService.openConfirmDialog(
        CoreConstants.dlgTitleConfirmation,
        'Please save your work before navigating to the ' + app.appTitle,
        CoreConstants.dlgBtnSaveContinue,
        CoreConstants.dlgBtnCancel
      ).catch(err => {
        console.error(err);
      });

      if (result === true) {
        this.targetedAppSettings = this.getAppSetting(app.appName);
        if (this.targetedAppSettings !== undefined) {
          this.appTitle = this.targetedAppSettings.appTitle;

          this.isAppSwitchSelected = true;
          this.storage.setItem(Constants.app, app.appName);
          this.storage.setItem(CoreConstants.email, Constants.empty);

          switch (this.targetedAppSettings.authenticationMechanism.name) {
            case AuthenticationType.AzureAd:
              if (this.azureAdService.isUserAuthenticated()) {
                this.swappedApp = app.appName;
                this.setSelectedApplication(app.appName);
                this.processAzureAdRequest();
              } else {
                this.storage.setItem(CoreConstants.isUserLoggedIn, Constants.empty);
                this.router.navigate(['/azureAd'], { queryParams: { app: app.appName } });
              }
              break;
            case AuthenticationType.Shield:
              this.isAuthorized(this.targetedAppSettings).then(response => {
                if (response === true) {
                  this.storage.setItem(Constants.app, this.defaultApp);
                  this.swappedApp = app.appName;
                  if (this.storage.getItem(Constants.app) === Applications.FoD) {
                    this.sendSwapEvent();
                  } else {
                    this.userName = this.storage.getItem('fullName');
                    this.setSelectedApplication(app.appName);
                  }
                } else {
                  this.storage.setItem(CoreConstants.isUserLoggedIn, Constants.empty);
                  this.router.navigate(['/login'], { queryParams: { app: app.appName } });
                }
              }).catch(error => {
                console.log(error);
                this.showToasterMessage(AlertMessageType.Danger, 'You are not authorized to access ' + app.appTitle + '.');
              });
              break;
            case AuthenticationType.ShieldPassiveFederation:
              if (this.shieldPassiveFedSvc.isUserAuthenticated()) {
                this.shieldPassiveFedSvc.GetRolesAndPermission().subscribe((authData) => {
                  if (authData) {
                    if (authData.isUserAuthenticated === 'true') {
                      this.userName = authData.username;
                      this.shieldPassiveFedSvc.sopBrowserSessionTimeout = authData.browserTimeout;
                      this.setSelectedApplication(app.appName);
                      this.startBrowserSessionTimer(authData.browserTimeout);
                    } else {
                      this.redirectToSopLoginPage();
                    }
                  } else {
                    this.redirectToSopLoginPage();
                  }
                });
              } else {
                this.redirectToSopLoginPage();
              }
              break;
            default: break;
          }
        }
      }
    }

  }

  public processAzureAdRequest() {
    this.azureAdService.getIdToken()
      .then((idToken) => {
        // tslint:disable-next-line: strict-type-predicates
        if (idToken !== undefined) {
          this.storage.setItem(Constants.app, Applications.CareQuality);
          let userAccessProfile = this.profileRoleHelper.getUserAccessProfile(idToken);
          // This code will be display login user email Id
          // To do: Need to update Azure AD code to get user display name
          this.userName = userAccessProfile.userName;
          if (userAccessProfile.appProfiles.length === 0) {
            SharedFunctions.sleep(CoreConstants.sleepTimeMs);
            userAccessProfile = this.profileRoleHelper.getUserAccessProfile(idToken);
          }
          if (!this.loginService.verifyEmail(userAccessProfile.userName)) {
            this.logout();
            return;
          }
          if (userAccessProfile.appProfiles.length === 0) {
            this.showMessage(CsopAlertMessageType.Danger, CoreConstants.notAuthorizedMsg);
          } else {
            // store userAccessProfile in store
            this.store.dispatch(new StoreUserProfile(userAccessProfile))
              .subscribe((dataProfile) => {
                if (dataProfile !== undefined &&
                  dataProfile.auth !== undefined) {
                  this.userProfile = dataProfile.auth;
                  this.setSelectedApplication(Applications.CareQuality);

                  this.appNotificationSubscription = this.appNotification.subscribe(
                    (data: Event) => {
                      const coreEvent: CoreEvent = (data as CustomEvent).detail;
                      this.toastr.info(coreEvent.payload, 'App ID::' + coreEvent.elementId);
                    }
                  );

                  this.appRequestSubscription = this.appRequest.subscribe(
                    (data: Event) => {
                      const coreEvent: CoreEvent = (data as CustomEvent).detail;
                      // const msg = new CoreResponse(coreEvent.elementId, CsopEventType.Response, this.userProfile);
                      if (this.user$ !== undefined) {
                        this.user$.subscribe(user => {
                          const docEvent = new CustomEvent(CsopEventType[CsopEventType.Response] + '_' + coreEvent.elementId,
                            { detail: user.token });
                          document.dispatchEvent(docEvent);
                        });
                      }
                    }
                  );
                } else {
                  this.toastr.warning('You are not authorized to access any application.');
                }
              },
                error => {
                  console.error(error);
                });
          }
        }
      }, (error) => {
        this.toastr.error('Failed to load Id token.');
        console.error(error);
      });
  }

  public startBrowserSessionTimer(timeoutMinutes: number) {
    this.sopSessionTimer$ = this.startTimer(timeoutMinutes).subscribe(async (resp) => {

      // If Inactivity popup is being displayed then don't open SOP Refresh pop up
      if (resp === 0 && this.csopUserIdleSvc.schedulerTime > Constants.DefaultSessionSchedulerTime) {
        this.sopSessionSchedulerTime = resp;
        return;
      }

      this.sopSessionSchedulerTime = resp;
      if (resp > Constants.MaxTimerLimit && this.csopUserIdleSvc.schedulerTime === Constants.DefaultSessionSchedulerTime) {
        this.toastr.error('Your session has expired. Please log in again.');
        if (this.sopSessionTimer$ !== undefined) {
          this.sopSessionTimer$.unsubscribe();
        }
        this.logout();
      }
      // tslint:disable-next-line:no-any
      let resultDialog: any; // tslint:disable-line:no-uninitialized
      this.setMsgForSessionTimeoutWarning(resp);
      if (resp === 0 && this.csopUserIdleSvc.schedulerTime === Constants.DefaultSessionSchedulerTime) {
        resultDialog = <boolean>await this.dialogService.openConfirmDialog(
          CoreConstants.confirmationMessage,
          'You will be logged out in 59 seconds. \n Do you want to stay signed in ?',
          CoreConstants.dlgBtnYes,
          CoreConstants.dlgBtnNo
        ).catch(err => {
          console.log(err);
          this.logout();
        });
      }
      if (resultDialog === true) {
        // reset session
        this.sopSessionTimer$.unsubscribe();
        this.refreshSOPSession();

      } else if (resultDialog === false) {
        this.sopSessionTimer$.unsubscribe();
        this.logout();
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  public windowCloseEvent() { // tslint:disable-line:no-any
    /* Note: Below line is added as system should
     * not logout user while system is redirect user
     * for Azure AD authentication.*/
    if (window.localStorage !== undefined) { // tslint:disable-line:strict-type-predicates
      // flag the page as being unloading
      window.localStorage['myUnloadEventFlag'] = new Date().getTime();
    }
    if (!this.targetedUrlIsAzureAd) {
      if (this.isAppSwitchSelected === false) {
        setTimeout(() => {
          if (this.reload === true) {
            this.logout();
          }
          // tslint:disable-next-line:no-magic-numbers
        }, 5000);

      }
    }
  }

  @HostListener('window:load', ['$event'])
  public windowReload() { // tslint:disable-line:no-any
    if (!window.location.href.includes('sop-app')) {
      return;
    }
    if (window.localStorage !== undefined) { // tslint:disable-line:strict-type-predicates
      let t0 = Number(window.localStorage['myUnloadEventFlag']);
      if (isNaN(t0)) { t0 = 0; }
      const t1 = new Date().getTime();
      const duration = t1 - t0;
      // tslint:disable-next-line:no-magic-numbers
      if (duration < 10 * 1000) {
        this.reload = false;
      }
    }
  }

  private continueSession() {
    this.http.get('SessionContinue', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).subscribe();
  }

  private detectUserAction() {
    this.csopUserIdleSvc.observeUserActivity.subscribe((authProvider: AuthenticationType) => {
      if (authProvider === AuthenticationType.Shield) {
        this.currentInteration = 0;
      } else if (authProvider === AuthenticationType.ShieldPassiveFederation) {
        console.log('In ShieldPassiveFederation');
        this.csopUserIdleSvc.resetTimer();
        // tslint:disable-next-line:no-magic-numbers
        if (Math.abs((Date.now() - this.storage.getItem(Constants.sessionTime)) / (1000 * 60)) >= 1) {
          this.storage.setItem(Constants.sessionTime, Date.now().toString());
          this.continueSession();
        }
      }
    });
  }

  private getAppSetting(
    appName: string
  ): IAppSettings | undefined {
    const appSetting = this.application.find((app: IAppSettings) => app.appName === appName);
    return appSetting !== undefined ? appSetting : undefined;
  }

  private async getShieldAuthenticated(
    appSettings: IAppSettings
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      let token = null; // tslint:disable-line: no-null-keyword

      const anotherShieldApp = this.application.find((app) =>
        app.authenticationMechanism.name === AuthenticationType.Shield
        && app.appName !== appSettings.appName);

      if (anotherShieldApp !== undefined) {
        const scopeValues = CsopLibSharedFunctions.getAuthenticationSettingScopeValue(anotherShieldApp.authenticationMechanism.setting);

        if (scopeValues !== undefined) {
          const tokenKey = anotherShieldApp.appName + '-' + scopeValues.tokenType;
          token = this.storage.getItem(tokenKey);
        }
        if (token !== null && token !== undefined && token !== '') { // tslint:disable-line:strict-type-predicates
          resolve(true);
        } else {
          resolve(await this.loginService.getRefreshTokenPromise(appSettings.appName, anotherShieldApp.appName));
        }
      } else {
        resolve(false);
      }
    });
  }

  private handleMessage(
    msg: Event
  ): void {
    this.toastr.info('shell received message: ', (<CustomEvent>msg).detail);
  }

  private handleSessionTimeout(): void {
    if (this.activeToast === undefined ||
      (
        // tslint:disable-next-line: strict-type-predicates
        this.activeToast !== undefined &&
        this.activeToast.message !== 'Session Timed out!'
      )) {
      this.activeToast = this.toastr.error('Session Timed out!');

      const delay = 2000;

      setTimeout(() => {
        this.logout();
      }, delay);
    }
  }

  private handleSwapping(swapEvent: CsopSwapEventType): void {
    switch (swapEvent) {
      case CsopSwapEventType.Continue:
        this.setSelectedApplication(this.swappedApp);
        break;
      case CsopSwapEventType.Cancel:
        this.defaultApp = this.storage.getItem(Constants.app);
    }
  }

  private async isAuthorized(
    appSettings: IAppSettings
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      let token = null; // tslint:disable-line: no-null-keyword
      if (appSettings !== undefined) { // tslint:disable-line:strict-type-predicates
        const scopeValues = CsopLibSharedFunctions.getAuthenticationSettingScopeValue(appSettings.authenticationMechanism.setting);
        if (scopeValues !== undefined) {
          token = this.storage.getItem(appSettings.appName + '-' + scopeValues.tokenType);
        }
        // tslint:disable-next-line:prefer-switch
        if (token === null || token === undefined || token === '') { // tslint:disable-line:strict-type-predicates
          await this.getShieldAuthenticated(appSettings)
            .catch(err => {
              console.error(err);
              resolve(false);
            })
            .then(
              res => {
                res === true ? resolve(true) : resolve(false);
              }
            );
        } else {
          resolve(true);
        }
      } else {
        resolve(false);
      }
    });
  }

  private loadApplication(
    app: AppProfile
  ) {
    let content = document.getElementById('content');

    // tslint:disable-next-line: strict-type-predicates
    if (content === null || content === undefined) {
      SharedFunctions.sleep(CoreConstants.sleepTimeMs);
      content = document.getElementById('content');
    }
    if (content !== null) {
      const script = document.createElement('script');
      script.src = app.appModulePath;

      content.appendChild(script);

      const element: HTMLElement = document.createElement(app.appName);
      content.appendChild(element);
      element.addEventListener('message', msg => this.handleMessage(msg));
      element.addEventListener('session timeout', () => this.handleSessionTimeout());

      content.addEventListener(CsopSwapEventType[CsopSwapEventType.Continue],
        () => this.handleSwapping(CsopSwapEventType.Continue));

      content.addEventListener(CsopSwapEventType[CsopSwapEventType.Cancel],
        () => this.handleSwapping(CsopSwapEventType.Cancel));

      element.setAttribute('clientid', app.appName);
      element.setAttribute('state', JSON.stringify(
        this.userProfile.appProfiles.find(t => t.appName === app.appName)));

      script.onerror = () => console.error(`error loading ${app.appName}`);
      this.stateSvc.registerClient(element);
    }
  }

  private redirectToSopLoginPage() {
    window.location.href = '/ShieldPassiveFederation/IssueSAMLRequest';
  }

  private refreshShieldToken(appName: string) {

    const appSettings = this.configService.config.csopsettings.applications.filter((app: IAppSettings) =>
      app.appName.toLowerCase() === appName.toLowerCase());

    const tokenScopeConfig = appSettings[0].authenticationMechanism.setting.filter((setting: ISetting) =>
      setting.name.toLowerCase() === CoreConstants.defaultAuthenticationSetting.toLowerCase());

    const authProvider = this.configService.config.csopsettings.authProviders.filter(
      (provider: IAuthProviders) => provider.name === AuthenticationType.Shield)[0];

    if (tokenScopeConfig.length > 0) {

      const scope = new AuthScopeInformation(
        tokenScopeConfig[0].relyingParty,
        tokenScopeConfig[0].scope,
        tokenScopeConfig[0].scopeValue,
        tokenScopeConfig[0].issueCompressToken);

      const userProfile = this.tokenManagerService.fetchTokenForShield(appName, authProvider, scope);

      if (userProfile !== undefined) {
        this.userProfileService.saveUserProfile(appName, CoreConstants.defaultAuthenticationSetting, userProfile);
        console.log('Profile Saved');
      } else {
        console.log('Token not Refreshed');
        this.logout();
      }
    }
  }

  private refreshSOPSession() {
    this.removeContent();
    this.shieldPassiveFedSvc.logout().subscribe(() => {
      this.storage.clear();
      if (!this.storage.getKey('ukey')) { // tslint:disable-line:strict-boolean-expressions
        this.storage.storeKey(JSON.stringify(SharedFunctions.makeRandomString(CoreConstants.keyLength)));
      }
      this.storage.setItem(Constants.app, 'sop-app');
      this.isAppSwitchSelected = true;
      this.redirectToSopLoginPage();
    }, // tslint:disable-next-line:no-any
      (err: any) => {
        console.log(err);
        this.isAppSwitchSelected = true;
        this.loginService.redirectToAppLauncher();
      });
  }

  private removeContent() {
    const content = document.getElementById('content');
    if (content !== null) {
      while (content.firstChild !== null) {
        content.removeChild(content.firstChild);
      }
    }
  }

  // private resetSOPBrowserSessionTimeout() {
  //   // Token is about to expire, so establish new session
  //   if (this.sopSessionSchedulerTime > Constants.DefaultSessionSchedulerTime) {
  //     this.refreshSOPSession();
  //   } else {
  //     // There is still some time pending to expire the token so get new session timeout
  //     this.shieldPassiveFedSvc.GetRolesAndPermission().subscribe((resp) => {
  //       if (resp) {
  //         if (resp.isUserAuthenticated === 'true') {
  //           this.startBrowserSessionTimer(resp.browserTimeout);
  //         }
  //       }
  //     });
  //   }
  // }

  private sendSwapEvent() {
    const swapEvent = new Event(CsopSwapEventType[CsopSwapEventType.Notify], { bubbles: false, cancelable: false });
    const app = this.storage.getItem('app');
    const appElement = document.getElementsByTagName(app)[0];
    appElement.dispatchEvent(swapEvent);
  }

  private setMsgForSessionTimeoutWarning(seconds: number) {
    const remainingSeconds = (Constants.Seconds - 1) - seconds;
    if (remainingSeconds <= -1) {
      this.logout();
    }
    const msg = 'You will be logged out in <b>' + remainingSeconds + '</b> seconds. \n Do you want to stay signed in ?';
    const modelbodydiv = document.getElementsByClassName('modal-body')[0] as HTMLDivElement;
    if (modelbodydiv !== undefined && modelbodydiv !== null) { // tslint:disable-line: strict-type-predicates
      modelbodydiv.innerHTML = msg;
    }
  }

  private setSelectedApplication(
    app: string
  ) {
    const listAppProfiles = this.userProfile.appProfiles;
    if (listAppProfiles.length > 0) {
      this.selectedApplication = listAppProfiles.find(X => X.appName.toLowerCase() === app.toLowerCase());
      // tslint:disable-next-line: strict-type-predicates
      if (this.selectedApplication !== undefined) {
        this.removeContent();
        this.isAppSwitchSelected = false;
        this.defaultApp = this.selectedApplication.appName;
        this.loadApplication(this.selectedApplication);
        this.storage.setItem('app', this.selectedApplication.appName);

        // event that trigger on selected application dom generated. Will execute one time on appload only.
        const selectedAppDom = document.querySelector(this.selectedApplication.appName) as Element;
        const config = { childList: true };
        const observer = new MutationObserver(() => {
          this.progressBarService.showHideProgressBar(false, this.progressBarId);
          observer.disconnect();
        });
        observer.observe(selectedAppDom, config);
        // tslint:disable-next-line:max-line-length
        if ((this.activeRoute.snapshot.url.length > 0 && this.activeRoute.snapshot.url[0].path === 'sop-app') || (this.deepLinkingUrl !== undefined && this.deepLinkingUrl.startsWith('/sop-app'))) { // tslint:disable-line:strict-type-predicates

          if (this.deepLinkingUrl !== undefined && this.deepLinkingUrl !== '') { // tslint:disable-line:strict-type-predicates
            this.location.go(this.location.normalize(this.deepLinkingUrl));
          } else {
            this.location.go(this.location.normalize(this.router.url));
          }

        } else {
          // Route to default route of the application
          this.location.go(this.location.normalize(this.selectedApplication.defaultNavigationPath));
        }

      } else {
        this.router.navigate(['/login'], { queryParams: { app: app } });
        return;
      }
    }
  }

  private showMessage(
    type: CsopAlertMessageType,
    message: string
  ) {
    const alert: ICsopAlertMessage = {
      type: type,
      message: message,
      alertComponentId: this.alertComponentId
    };
    this.alertService.open(alert);
  }

  private showToasterMessage(
    type: AlertMessageType,
    message: string
  ) {
    if (this.activeToast === undefined ||
      (
        // tslint:disable-next-line: strict-type-predicates
        this.activeToast !== undefined &&
        (
          this.activeToast.message !== message ||
          this.activeToast.toastRef.isInactive()
        ))) {
      switch (type) {
        case AlertMessageType.Danger:
          this.activeToast = this.toastr.error(message);
          break;
        case AlertMessageType.Info:
          this.activeToast = this.toastr.info(message);
          break;
        case AlertMessageType.Warning:
          this.activeToast = this.toastr.warning(message);
          break;
        case AlertMessageType.Success:
          this.activeToast = this.toastr.success(message);
          break;
      }
    }
  }

  // tslint:disable-next-line:no-any
  private startTimer(timeoutMinutes: number): Observable<any> {
    if (timeoutMinutes < 0) {
      return new Observable(observer => { observer.next(Constants.MaxSessionCounterLimit); });
    } else {
      const dueTime = timeoutMinutes * Constants.Seconds * Constants.MilliSeconds;
      return timer(dueTime, Constants.MilliSeconds);
    }
  }
}

// tslint:disable-line:max-file-line-count
