/* P r o p r i e t a r y N o t i c e */
/* Unpublished © 2020 Allscripts Healthcare, LLC and/or its affiliates. All Rights
Reserved.
*
* P r o p r i e t a r y N o t i c e: This software has been provided pursuant to a License Agreement, with Allscripts
Healthcare, LLC and/or its affiliates, containing restrictions on its use. This software contains valuable trade secrets
and proprietary information of Allscripts Healthcare, LLC and/or its affiliates and is protected by trade secret and
copyright law. This software may not be copied or distributed in any form or medium, disclosed to any third parties,
or used in any manner not provided for in said License Agreement except with prior written authorization from
Allscripts Healthcare, LLC and/or its affiliates. Notice to U.S. Government Users: This software is “Commercial
Computer Software.”
Allscripts Common Services Operations Portal™ is a trademark of Allscripts Healthcare, LLC and/or its affiliates.
*
*
*/
/* P r o p r i e t a r y N o t i c e */

import { Injectable } from '@angular/core';
import { Observable, fromEvent, merge, Subject, timer, Subscription } from 'rxjs';

import {
  AuthenticationType,
  Constants
} from '../../../public-api';

@Injectable({
  providedIn: 'root'
})

export class CsopUserIdleService {
  public expired$: Subject<number> = new Subject<number>();
  public observeUserActivity: Subject<AuthenticationType> = new Subject<AuthenticationType>();
  public schedulerTime = Constants.DefaultSessionSchedulerTime;
  // tslint:disable-next-line:no-any
  private idle$: Observable<any> | undefined; // tslint:disable-line:no-uninitialized
  private idleSubscription!: Subscription;
  private timeOutMilliSeconds: number | undefined; // tslint:disable-line:no-uninitialized
  // tslint:disable-next-line:no-any
  private timer$: any; // tslint:disable-line:no-uninitialized

  public constructor(
    // tslint:disable-next-line:no-empty
  ) { }
  public resetTimer() {
    this.timer$.unsubscribe();
    this.startTimer();
  }

  // tslint:disable-next-line:no-any
  public startWatching(timeOutSeconds: any, authenticationType: AuthenticationType): Observable<any> {
    this.idle$ = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'keypress'),
      fromEvent(document, 'keyup'),
      fromEvent(document, 'DOMMouseScroll'),
      fromEvent(document, 'mousewheel'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'MSPointerMove'),
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize'),
    );

    this.timeOutMilliSeconds = timeOutSeconds * 1000; // tslint:disable-line:no-magic-numbers

    this.idleSubscription = this.idle$.subscribe((_res) => {
      if (this.schedulerTime < 0) {
        // If inactivity popup is open then don't reset timer.
        // Notify consumer component so it can take appropriate actions as per their requirement
        this.observeUserActivity.next(authenticationType);
      }
    });

    this.startTimer();

    return this.expired$;
  }

  public stopTimer() {
    this.timer$.unsubscribe();
    this.idleSubscription.unsubscribe();
  }

  private startTimer() {
    // tslint:disable-next-line:no-magic-numbers
    this.timer$ = timer(this.timeOutMilliSeconds, 1000).subscribe((_res) => {
      this.schedulerTime = _res;
      this.expired$.next(_res);
    });
  }
}
