import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import { StorageService } from 'src/app/core/services/storage.service';
import { AuthScopeInformation, LoginResponse, } from 'src/app/core/model/index';
import { ApplicationNames, AuthScope, CoreConstants, } from 'src/app/core/constants';
import { UserProfileService } from '../userprofileservice/userprofile.service';
import { AuthenticationType, AuthProviders, CsopSettings, Validations, CsopLibSharedFunctions, Constants } from '../../../public-api';
import { ConfigService } from '../configservice/configuration.service';
import * as i0 from "@angular/core";
import * as i1 from "../configservice/configuration.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../../../../../../src/app/core/services/storage.service";
import * as i4 from "../userprofileservice/userprofile.service";
var LoginService = (function () {
    function LoginService(configService, http, storage, userProfileService) {
        this.configService = configService;
        this.http = http;
        this.storage = storage;
        this.userProfileService = userProfileService;
        this.isUserAuthenticated = false;
        this.authProvider = new AuthProviders();
        this.csopSettings = new CsopSettings();
        this.isUserAuthenticated = false;
        this.scope = new AuthScopeInformation('', AuthScope.Tenant, '');
        this.csopSettings = this.configService.config.csopsettings;
        this.authProvider = this.csopSettings.authProviders.filter(function (provider) { return provider.name === AuthenticationType.Shield; })[0];
    }
    LoginService.prototype.getRefreshToken = function (app, originApp) {
        var _this = this;
        if (originApp === void 0) { originApp = app; }
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        var loginResponse = new LoginResponse(false);
        this.setApplicationScopeObject(app);
        var refreshUrl = this.authProvider.endPoint + 'api/authentication/refresh/';
        var samlToken = this.storage.getItem(originApp + '-' + CoreConstants.SamlAuthToken);
        return this.http.post(refreshUrl, JSON.stringify({ AuthToken: samlToken, Scope: this.scope, IssueCompressToken: this.scope.issueCompressToken }), {
            observe: 'response',
            headers: headers,
            withCredentials: false
        }).map(function (response) {
            loginResponse.loginAllowed = true;
            var userProfile = response.body.result;
            _this.saveTokens(userProfile, app);
            if (app !== originApp) {
                _this.setUserRoles(userProfile, app);
            }
            return Observable.of(loginResponse);
        }).pipe(catchError(function (error) {
            console.log(error);
            loginResponse.validation = Validations.refreshToken;
            return Observable.of(loginResponse);
        }));
    };
    LoginService.prototype.getRefreshTokenPromise = function (app, originApp) {
        if (originApp === void 0) { originApp = app; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var promise;
            return tslib_1.__generator(this, function (_a) {
                promise = this.getRefreshToken(app, originApp).toPromise();
                return [2, new Promise(function (resolve) {
                        promise.catch(function (error) {
                            console.error(error);
                            resolve(false);
                        }).then(function (tokenResponse) {
                            if (tokenResponse instanceof LoginResponse) {
                                resolve(tokenResponse.loginAllowed);
                            }
                            else {
                                tokenResponse.subscribe(function (tokenResponse$) {
                                    resolve(tokenResponse$.loginAllowed);
                                });
                            }
                        });
                    })];
            });
        });
    };
    LoginService.prototype.login = function (userName, upName) {
        var _this = this;
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        var loginResponse = new LoginResponse(false);
        this.isUserAuthenticated = false;
        var loginUrl = this.authProvider.endPoint + 'api/authentication/login/';
        this.setApplicationScopeObject(this.storage.getItem('app'));
        return this.http.post(loginUrl, JSON.stringify({ UserName: userName, Password: upName, Scope: this.scope, IssueCompressToken: this.scope.issueCompressToken }), {
            observe: 'response',
            headers: headers,
            withCredentials: true
        }).map(function (response) {
            var userProfile = response.body.result;
            loginResponse = _this.setUserData(userProfile, _this.storage.getItem('app'));
            return Observable.of(loginResponse);
        }).pipe(catchError(function () {
            loginResponse.validation = Validations.loginFailed;
            return Observable.of(loginResponse);
        }));
    };
    LoginService.prototype.redirectToAppLauncher = function () {
        window.location.replace('/');
    };
    LoginService.prototype.setUserRoles = function (userProfile, app) {
        var loginResponse = new LoginResponse(false);
        if (userProfile !== null) {
            if (userProfile.permissions.length > 0) {
                loginResponse.loginAllowed = true;
                this.storage.setItem(app + '-roles', JSON.stringify(userProfile.permissions));
                this.storage.setItem('fullName', userProfile.lastName + ',' + userProfile.firstName);
                if (!this.verifyEmail(JSON.stringify(userProfile.email))) {
                    loginResponse.loginAllowed = false;
                    loginResponse.validation = Validations.accessDenied;
                    this.storage.clear();
                    this.redirectToAppLauncher();
                }
            }
            else {
                loginResponse.validation = Validations.unAuthorized + ApplicationNames.get(this.storage.getItem('app'));
            }
        }
        return loginResponse;
    };
    LoginService.prototype.verifyEmail = function (userEmail) {
        var email = this.storage.getItem(CoreConstants.email);
        if (email === null
            || email === undefined
            || email === CoreConstants.empty) {
            if (userEmail !== null
                && userEmail !== undefined) {
                userEmail = userEmail.replace(/"/g, '');
                this.storage.setItem(CoreConstants.email, userEmail.trim());
            }
            return true;
        }
        else {
            userEmail = userEmail.replace(/"/g, '');
            return (userEmail.toLowerCase().trim() === email.toLowerCase());
        }
    };
    LoginService.prototype.saveTokens = function (userProfile, app) {
        var jwtAuthToken = userProfile.tokenInfo.jwtToken;
        this.storage.setItem(app + '-' + CoreConstants.JwtAuthToken, jwtAuthToken);
        var samlAuthToken = userProfile.tokenInfo.samlToken;
        this.storage.setItem(app + '-' + CoreConstants.SamlAuthToken, samlAuthToken);
        this.setRefreshTokenTimer(userProfile.tokenInfo.validTo, app);
        this.userProfileService.saveUserProfile(app, Constants.defaultUserProfile, userProfile);
    };
    LoginService.prototype.setApplicationScopeObject = function (app, scopeName) {
        if (scopeName === void 0) { scopeName = Constants.defaultAuthenticationSettingName; }
        if (this.csopSettings.applications.length >= 0) {
            var apps = this.csopSettings.applications
                .filter(function (application) { return application.appName.toLowerCase() === app.toLowerCase(); });
            if (Array.isArray(apps) && apps.length > 0) {
                var scopeValues = CsopLibSharedFunctions.getAuthenticationSettingScopeValue(apps[0].authenticationMechanism.setting, scopeName);
                if (scopeValues !== undefined) {
                    this.scope = new AuthScopeInformation(scopeValues.relyingParty, scopeValues.scope, scopeValues.scopeValue, scopeValues.issueCompressToken);
                }
            }
        }
    };
    LoginService.prototype.setRefreshTokenTimer = function (expiryDateUTC, app) {
        var _this = this;
        var threshold = 60000;
        var timeout = (new Date(expiryDateUTC)).valueOf() - (new Date()).valueOf() - threshold;
        setTimeout(function () {
            _this.getRefreshToken(app).subscribe();
        }, timeout);
    };
    LoginService.prototype.setUserData = function (userProfile, app) {
        this.isUserAuthenticated = false;
        var loginResponse = this.setUserRoles(userProfile, app);
        if (loginResponse.loginAllowed) {
            this.saveTokens(userProfile, app);
            this.isUserAuthenticated = true;
        }
        return loginResponse;
    };
    LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.ConfigService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.StorageService), i0.ɵɵinject(i4.UserProfileService)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
