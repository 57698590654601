/* P r o p r i e t a r y N o t i c e */
/* Unpublished © 2020 Allscripts Healthcare, LLC and/or its affiliates. All Rights
Reserved.
*
* P r o p r i e t a r y N o t i c e: This software has been provided pursuant to a License Agreement, with Allscripts
Healthcare, LLC and/or its affiliates, containing restrictions on its use. This software contains valuable trade secrets
and proprietary information of Allscripts Healthcare, LLC and/or its affiliates and is protected by trade secret and
copyright law. This software may not be copied or distributed in any form or medium, disclosed to any third parties,
or used in any manner not provided for in said License Agreement except with prior written authorization from
Allscripts Healthcare, LLC and/or its affiliates. Notice to U.S. Government Users: This software is “Commercial
Computer Software.”
Allscripts Common Services Operations Portal is a trademark of Allscripts Healthcare, LLC and/or its affiliates.
*
*
*/
/* P r o p r i e t a r y N o t i c e */

import {
  Injectable,
} from '@angular/core';
import {
  NgElement,
  WithProperties
} from '@angular/elements';

import {
  CsopAlertComponent,
  CsopInlineAlertComponent,
  ICsopAlertMessage,
  ICsopInlineAlertMessage
} from '../../../public-api';
import { AlertConstants } from '../../types/constants';

@Injectable({
  providedIn: 'root'
})
export class CsopAlertService {

  public close(alertComponentId: string) {
    const content = document.getElementById(alertComponentId);
    if (content !== null) {
      let alertNodes =
        content.getElementsByTagName('asg-alert');

      if (alertNodes.length === 0) {
        alertNodes =
          content.getElementsByTagName('csop-inline-alert');
      }

      while (alertNodes.length > 0) {
        alertNodes[0].remove();
      }
    } else {
      // To-do central logging component
    }
  }

  public open(
    alert: ICsopAlertMessage,
    closeExisting: boolean = false
  ) {

    // get placeholder element
    const content = document.getElementById(alert.alertComponentId);
    if (content !== null) {

      if (closeExisting === true) {
        // Before creating new alert, close one if already exists
        this.close(alert.alertComponentId);
      }

      // Create element
      const alertElement = this.createAlertElement(false);

      this.show(alertElement, content, alert);
    } else {
      // To-do central logging component
    }
  }

  public showInline(alert: ICsopInlineAlertMessage) {

    // get placeholder element
    const content = document.getElementById(alert.alertComponentId);
    if (content !== null) {

      // Before creating new inline alert, close one if already exists
      this.close(alert.alertComponentId);

      // Create element
      const alertElement = this.createAlertElement(true);

      this.show(alertElement, content, alert);
    } else {
      // To-do central logging component
    }
  }

  private createAlertElement(
    inlineAlert: boolean
  ): NgElement & (
    WithProperties<CsopInlineAlertComponent> |
    WithProperties<CsopAlertComponent>
  ) {
    if (inlineAlert) {
      return document.createElement('csop-inline-alert') as
        NgElement & WithProperties<CsopInlineAlertComponent>;
    } else {
      return document.createElement('asg-alert') as
        NgElement & WithProperties<CsopAlertComponent>;
    }
  }

  private show(
    popupEl: NgElement & (
      WithProperties<CsopInlineAlertComponent> |
      WithProperties<CsopAlertComponent>
    ),
    content: HTMLElement,
    alert: ICsopInlineAlertMessage | ICsopAlertMessage
  ) {
    // Listen to the close event
    popupEl.addEventListener('closed',
      () => content.removeChild(popupEl));

    // assign alert data
    popupEl.alertmessage = alert;

    // append any child elemnt needed for special decision making from alert component.
    if (alert.decisionElement !== undefined) {
      alert.decisionElement.setAttribute('slot', AlertConstants.alertMessageResolveSlotId);
      popupEl.appendChild(alert.decisionElement);
    }
    // Add to the DOM
    content.appendChild(popupEl);
  }
}
