import * as tslib_1 from "tslib";
import * as signalR from '@aspnet/signalr';
import { StorageService } from 'src/app/core/services/storage.service';
import { CoreConstants } from 'src/app/core/constants';
import { Constants } from '../../types/constants';
import { AzureAdService } from '../azureservices/azure-ad-service';
import { ConfigService } from '../configservice/configuration.service';
import { GenericDataService } from '../dataservice/generic-data.service.';
import { DataRequest, HttpActionType } from '../../../../../../projects/csop-lib/src/public-api';
import { ShieldPassiveFederationService } from '../shieldpassivefederationservice/shield-passive-federation-service';
import * as i0 from "@angular/core";
import * as i1 from "../azureservices/azure-ad-service";
import * as i2 from "../configservice/configuration.service";
import * as i3 from "../dataservice/generic-data.service.";
import * as i4 from "../shieldpassivefederationservice/shield-passive-federation-service";
import * as i5 from "../../../../../../src/app/core/services/storage.service";
var CsopSignalRService = (function () {
    function CsopSignalRService(azureAdService, configService, genericDataService, shieldPassiveFedSvc, storage) {
        var _this = this;
        this.azureAdService = azureAdService;
        this.configService = configService;
        this.genericDataService = genericDataService;
        this.shieldPassiveFedSvc = shieldPassiveFedSvc;
        this.storage = storage;
        this.connectionStarted = false;
        this.listenerMethod = function () {
            _this.hubConnection.on('LogoutMessage', function () {
                _this.azureAdService.sendWarningMessage();
                var timeout = 10000;
                setInterval(function () {
                    _this.shieldPassiveFedSvc.logout().subscribe(function () { return undefined; });
                    _this.azureAdService.logout();
                }, timeout);
            });
        };
    }
    CsopSignalRService.prototype.saveConnectionData = function (id) {
        var email = this.storage.getItem(CoreConstants.email);
        return this.genericDataService.execute(new DataRequest(HttpActionType.GET, Constants.signalR, Constants.connection, id, email.toLowerCase()));
    };
    CsopSignalRService.prototype.saveConnectionDetails = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.saveConnectionData(id).toPromise().catch(function (err) {
                            console.error(err);
                        })];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    CsopSignalRService.prototype.startConnection = function () {
        var _this = this;
        if (!this.connectionStarted) {
            var url = this.configService.config.csopsettings.redirectUri;
            this.hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(url + Constants.hub)
                .configureLogging(signalR.LogLevel.Error)
                .build();
            this.hubConnection.start().catch(function (err) { return console.error(err.toString()); }).then(function () {
                console.log('Connection is established');
                _this.listenerMethod();
                _this.hubConnection.invoke('GetConnectionID')
                    .then(function (data) {
                    _this.saveConnectionDetails(data);
                });
            });
            this.connectionStarted = true;
        }
    };
    CsopSignalRService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CsopSignalRService_Factory() { return new CsopSignalRService(i0.ɵɵinject(i1.AzureAdService), i0.ɵɵinject(i2.ConfigService), i0.ɵɵinject(i3.GenericDataService), i0.ɵɵinject(i4.ShieldPassiveFederationService), i0.ɵɵinject(i5.StorageService)); }, token: CsopSignalRService, providedIn: "root" });
    return CsopSignalRService;
}());
export { CsopSignalRService };
