import { Router } from '@angular/router';
import { Constants } from 'projects/csop-lib/src/public-api';
import { Applications, CoreConstants } from '../constants';
import { ShieldPassiveFederationService } from '../../../../projects/csop-lib/src/lib/services/shieldpassivefederationservice/shield-passive-federation-service';
import { StorageService } from '../services';
import { SharedFunctions } from '../helpers';
import * as i0 from "@angular/core";
import * as i1 from "../../../../projects/csop-lib/src/lib/services/shieldpassivefederationservice/shield-passive-federation-service";
import * as i2 from "../services/storage.service";
import * as i3 from "@angular/router";
var CSOPRoutingAuthGuard = (function () {
    function CSOPRoutingAuthGuard(shieldPassiveFedSvc, storage, router) {
        this.shieldPassiveFedSvc = shieldPassiveFedSvc;
        this.storage = storage;
        this.router = router;
    }
    CSOPRoutingAuthGuard.prototype.canActivate = function () {
        var _this = this;
        if (window.location.hash.toString().startsWith('#/sop-app') && customElements.get(Applications.Sop) === undefined) {
            this.storage.setItem('deepLinkingUrl', window.location.hash.replace('#', ''));
            if (this.storage.getItem('app') === Applications.Sop) {
                return this.shieldPassiveFedSvc.GetRolesAndPermission().toPromise().then(function (auth) {
                    if (auth) {
                        if (auth.isUserAuthenticated !== 'true') {
                            _this.router.navigate(['/loginPassiveFederation']);
                            return false;
                        }
                        else {
                            _this.storage.setItem(CoreConstants.email, auth.username);
                            return true;
                        }
                    }
                    else {
                        _this.router.navigate(['/loginPassiveFederation']);
                        return false;
                    }
                });
            }
            else {
                if (!this.storage.getKey('ukey')) {
                    this.storage.storeKey(JSON.stringify(SharedFunctions.makeRandomString(CoreConstants.keyLength)));
                }
                this.storage.setItem(Constants.app, 'sop-app');
                this.router.navigate(['/loginPassiveFederation']);
                return false;
            }
        }
        else {
            return false;
        }
    };
    CSOPRoutingAuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CSOPRoutingAuthGuard_Factory() { return new CSOPRoutingAuthGuard(i0.ɵɵinject(i1.ShieldPassiveFederationService), i0.ɵɵinject(i2.StorageService), i0.ɵɵinject(i3.Router)); }, token: CSOPRoutingAuthGuard, providedIn: "root" });
    return CSOPRoutingAuthGuard;
}());
export { CSOPRoutingAuthGuard };
