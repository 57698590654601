import * as i0 from "./csop-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./csop-dialog-injection.directive";
import * as i3 from "@angular/common";
import * as i4 from "./csop-modal.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
var styles_CsopModalComponent = [i0.styles];
var RenderType_CsopModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CsopModalComponent, data: {} });
export { RenderType_CsopModalComponent as RenderType_CsopModalComponent };
function View_CsopModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_CsopModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.decline() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.btnCancelText; _ck(_v, 1, 0, currVal_0); }); }
function View_CsopModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.accept() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.btnOkText; _ck(_v, 1, 0, currVal_0); }); }
export function View_CsopModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { csopDialogInjection: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CsopModalComponent_1)), i1.ɵdid(10, 16384, [[1, 4]], 0, i2.CsopDialogInjectionDirective, [i1.ViewContainerRef], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CsopModalComponent_2)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CsopModalComponent_3)), i1.ɵdid(15, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.showCancelButton; _ck(_v, 13, 0, currVal_2); var currVal_3 = _co.showOkButton; _ck(_v, 15, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 8, 0, currVal_1); }); }
export function View_CsopModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "csop-modal", [], null, null, null, View_CsopModalComponent_0, RenderType_CsopModalComponent)), i1.ɵdid(1, 4374528, null, 0, i4.CsopModalComponent, [i1.ComponentFactoryResolver, i1.ChangeDetectorRef, i5.NgbActiveModal], null, null)], null, null); }
var CsopModalComponentNgFactory = i1.ɵccf("csop-modal", i4.CsopModalComponent, View_CsopModalComponent_Host_0, {}, {}, []);
export { CsopModalComponentNgFactory as CsopModalComponentNgFactory };
