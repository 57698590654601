import { AuthScopeInformation } from '../../../../../../src/app/core/model/auth-scope-information.model';
import { ConfigService } from '../configservice/configuration.service';
import { UserProfileService } from '../userprofileservice/userprofile.service';
import { AuthenticationType, TokenType, } from '../../../public-api';
import { Constants } from '../../types/constants';
import * as i0 from "@angular/core";
import * as i1 from "../configservice/configuration.service";
import * as i2 from "../userprofileservice/userprofile.service";
var TokenManagerService = (function () {
    function TokenManagerService(configService, userProfileService) {
        this.configService = configService;
        this.userProfileService = userProfileService;
        if (this.configService.config.csopsettings.applications.length === 0) {
            this.configService.loadUrl('../assets/csopsettings.json');
        }
    }
    TokenManagerService.prototype.fetchTokenForShield = function (appName, authProvider, scope) {
        var url = authProvider.endPoint + 'api/authentication/refresh/';
        var samlToken = '';
        var userDefaultProfile = this.userProfileService.getUserProfile(appName, Constants.defaultUserProfile);
        samlToken = userDefaultProfile !== undefined ? this.getTokenByType(userDefaultProfile.tokenInfo, TokenType.SAML) : '';
        var userProfile;
        var request = new XMLHttpRequest();
        request.open('POST', url, false);
        request.setRequestHeader('Content-Type', 'application/json');
        request.send(JSON.stringify({ AuthToken: samlToken, Scope: scope, IssueCompressToken: scope.issueCompressToken }));
        if (request.responseText.indexOf('Faulted state') > 0) {
            return undefined;
        }
        var response = JSON.parse(request.responseText);
        userProfile = response.result;
        return userProfile;
    };
    TokenManagerService.prototype.getToken = function (tokenReqeust) {
        var loginUserProfile = this.userProfileService.getUserProfile(tokenReqeust.appName, tokenReqeust.tokenFor);
        if (loginUserProfile !== undefined && loginUserProfile !== null) {
            if (this.isTokenIsValid(loginUserProfile.tokenInfo)) {
                return this.getTokenByType(loginUserProfile.tokenInfo, tokenReqeust.tokenType);
            }
        }
        var appSettings = this.configService.config.csopsettings.applications.filter(function (app) {
            return app.appName.toLowerCase() === tokenReqeust.appName.toLowerCase();
        });
        var tokenScopeConfig = appSettings[0].authenticationMechanism.setting.filter(function (setting) {
            return setting.name.toLowerCase() === tokenReqeust.tokenFor.toLowerCase();
        });
        var authProvider = this.configService.config.csopsettings.authProviders.filter(function (provider) { return provider.name === tokenReqeust.authenticationType; })[0];
        if (tokenScopeConfig.length > 0) {
            if (tokenReqeust.authenticationType === AuthenticationType.Shield) {
                var scope = new AuthScopeInformation(tokenScopeConfig[0].relyingParty, tokenScopeConfig[0].scope, tokenScopeConfig[0].scopeValue, tokenScopeConfig[0].issueCompressToken);
                var userProfile = this.fetchTokenForShield(tokenReqeust.appName, authProvider, scope);
                if (userProfile !== undefined) {
                    this.userProfileService.saveUserProfile(tokenReqeust.appName, tokenReqeust.tokenFor, userProfile);
                    return this.getTokenByType(userProfile.tokenInfo, tokenReqeust.tokenType);
                }
            }
        }
    };
    TokenManagerService.prototype.getTokenByType = function (tokenInfo, tokenType) {
        if (tokenType === TokenType.SAML) {
            return tokenInfo.samlToken;
        }
        else if (tokenType === TokenType.Jwt) {
            return tokenInfo.jwtToken;
        }
        return '';
    };
    TokenManagerService.prototype.isTokenIsValid = function (token) {
        var threshold = 60000;
        return (new Date(token.validTo)).valueOf() - (new Date()).valueOf() > threshold;
    };
    TokenManagerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TokenManagerService_Factory() { return new TokenManagerService(i0.ɵɵinject(i1.ConfigService), i0.ɵɵinject(i2.UserProfileService)); }, token: TokenManagerService, providedIn: "root" });
    return TokenManagerService;
}());
export { TokenManagerService };
