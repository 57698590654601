import { EventEmitter, OnDestroy, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { interval } from 'rxjs';
var CsopAlertComponent = (function () {
    function CsopAlertComponent(sanitizer) {
        this.sanitizer = sanitizer;
        this.closed = new EventEmitter();
        this.richText = '';
        this.alert = {
            type: '',
            message: '',
            alertComponentId: '',
            autoCloseTimer: 0,
            isRichText: false
        };
    }
    Object.defineProperty(CsopAlertComponent.prototype, "alertmessage", {
        set: function (alertmessage) {
            var _this = this;
            this.alert = alertmessage;
            if (this.alert.autoCloseTimer !== undefined && this.alert.autoCloseTimer > 0) {
                this.timeoutSubscription = interval(this.alert.autoCloseTimer)
                    .subscribe(function () {
                    _this.close();
                });
            }
            if (this.alert.isRichText !== undefined && this.alert.isRichText === true) {
                var sanitizedText = this.sanitizer.sanitize(SecurityContext.HTML, this.alert.message);
                if (sanitizedText !== null && sanitizedText !== '') {
                    this.richText = this.sanitizer.bypassSecurityTrustHtml(this.alert.message);
                }
            }
            else {
                this.richText = this.alert.message;
            }
        },
        enumerable: true,
        configurable: true
    });
    CsopAlertComponent.prototype.close = function () {
        this.closed.next(true);
    };
    CsopAlertComponent.prototype.ngOnDestroy = function () {
        if (this.timeoutSubscription !== undefined) {
            this.timeoutSubscription.unsubscribe();
        }
    };
    return CsopAlertComponent;
}());
export { CsopAlertComponent };
